import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SocketContextProvider from './contexts/socketContext';
import ThemeContextProvider from './contexts/themeContext';
import store from './redux/store';
import App from './App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <SocketContextProvider>
        <Provider store={store}>
            <ThemeContextProvider>
                <Router>
                    <Routes>
                        <Route path="/*" element={<App />} />
                    </Routes>
                </Router>
            </ThemeContextProvider>
        </Provider>
    </SocketContextProvider>
);
