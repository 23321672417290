import { createSlice } from '@reduxjs/toolkit';
import { ObjectId } from 'bson';
import { ROLES_LIST } from '../../configs/constants';

export const initialChatSetting = {
    activeHour: 9,
    activeMinute: 0,
    inactiveHour: 21,
    inactiveMinute: 0,
    inactiveMessage: `Selamat datang!
Terima kasih telah menghubungi kami.
Kami akan segera membalas chat begitu kami online.`
};

const initialState = {
    getOneLoading: true,
    getOneResult: null,
    getOneHasMore: false,
    getOneError: null,
    getOneChatIterator: null,

    getChatSettingResult: initialChatSetting,

    isSellerOnline: true,

    getSellerUnreadChatLoading: true,
    getSellerUnreadChatResult: 0,
    getSellerUnreadChatError: null,

    getAllLoading: true,
    getAllResult: null,
    getAllError: null,
    postError: null
};
export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        resetState: () => initialState,
        getOneStart: (state) => {
            state.getOneLoading = true;
        },
        getOneEnd: (state) => {
            state.getOneLoading = false;
        },
        getOneSuccess: (state, action) => {
            if (action.payload.result === null) return;
            state.getOneResult = state.getOneResult === null ? action.payload.result : [...new Map([...state.getOneResult, ...action.payload.result].map((v) => [v?._id, v])).values()];
            state.getOneHasMore = action.payload.hasMore;
            state.getOneError = null;
        },
        getOneFailure: (state, action) => {
            state.getOneError = action.payload;
        },
        getOneNextPage: (state) => {
            if (state.getOneResult === null) return;
            state.getOneChatIterator = state.getOneResult?.[state.getOneResult?.length - 1]?.createdAt;
        },
        pushOne: (state, action) => {
            if (action.payload?.sender === ROLES_LIST.Seller) {
                const sellerChat = { ...action.payload, sellerId: action.payload?.sellerDocument };
                const sellerChatMap = new Map(Object.entries(sellerChat));
                sellerChatMap.delete('sellerDocument');
                const sellerChatNew = Object.fromEntries(sellerChatMap);

                state.getOneResult = state.getOneResult === null ? [sellerChatNew] : [...new Map([sellerChatNew, ...state.getOneResult].map((v) => [v?._id, v])).values()];
                return;
            }
            state.getOneResult = state.getOneResult === null ? [action.payload] : [...new Map([action.payload, ...state.getOneResult].map((v) => [v?._id, v])).values()];
        },
        chatBotPushOne: (state, action) => {
            if (state.getOneResult === null) return;
            const isChatBotExist = state.getOneResult.some((chat) => chat?.secondaryMessage);
            if (isChatBotExist) return;
            state.getOneResult = [...new Map([action.payload, ...state.getOneResult].map((v) => [v?._id, v])).values()];
        },
        pushOneError: (state, action) => {
            const newErrorChatDoc = { ...action.payload, _id: new ObjectId().toString(), isError: true, message: 'Terjadi kesalahan saat mengirim pesan.' };
            state.getOneResult = state.getOneResult === null ? [newErrorChatDoc] : [...new Map([newErrorChatDoc, ...state.getOneResult].map((v) => [v?._id, v])).values()];
        },

        getChatSetting: (state, action) => {
            if (action.payload === null) return;
            state.getChatSettingResult = action.payload;
            state.getOneError = null;
        },
        setChatSetting: (state, action) => {
            state.getChatSettingResult = { ...state.getChatSettingResult, ...action.payload };
            state.getOneError = null;
        },

        setIsSellerOnline: (state, action) => {
            state.isSellerOnline = action.payload?.some?.((onlineUser) => onlineUser?.userRole === ROLES_LIST.Seller);
        },
        getSellerUnreadChatStart: (state) => {
            state.getSellerUnreadChatLoading = true;
        },
        getSellerUnreadChatEnd: (state) => {
            state.getSellerUnreadChatLoading = false;
        },
        getSellerUnreadChatSuccess: (state, action) => {
            state.getSellerUnreadChatResult = action.payload;
            state.getSellerUnreadChatError = null;
        },
        getSellerUnreadChatError: (state, action) => {
            state.getSellerUnreadChatError = action.payload;
        },

        getAllStart: (state) => {
            state.getAllLoading = true;
        },
        getAllEnd: (state) => {
            state.getAllLoading = false;
        },
        getAllSuccess: (state, action) => {
            if (state.getAllResult === null) {
                state.getAllResult = [{ buyerId: action.payload.buyerId, chatList: [...action.payload.result], hasMore: action.payload.hasMore, createdAt: action.payload.result?.[action.payload.result.length - 1]?.createdAt, updatedAt: action.payload.result?.[action.payload.result.length - 1]?.updatedAt }];
                return;
            }
            const isChatExist = state.getAllResult?.some?.((chatDoc) => chatDoc?.buyerId === action.payload.buyerId);
            if (isChatExist) {
                state.getAllResult = state.getAllResult?.map?.((chatDoc) => {
                    if (chatDoc?.buyerId === action.payload.buyerId) {
                        const chatList = chatDoc?.chatList ? [...new Map([...chatDoc?.chatList, ...action.payload.result].map((v) => [v?._id, v])).values()] : [...action.payload.result];
                        return { ...chatDoc, chatList, hasMore: action.payload.hasMore, createdAt: chatList?.[chatList.length - 1]?.createdAt, updatedAt: chatList?.[chatList.length - 1]?.updatedAt };
                    }
                    return chatDoc;
                });
                return;
            }
            state.getAllResult = [...new Map([{ buyerId: action.payload.buyerId, chatList: [...action.payload.result], hasMore: action.payload.hasMore, createdAt: action.payload.result?.[action.payload.result.length - 1]?.createdAt, updatedAt: action.payload.result?.[action.payload.result.length - 1]?.updatedAt }, ...state.getAllResult].map((v) => [v?.buyerId, v])).values()];
        },
        getAllFailure: (state, action) => {
            const newErrorChatDoc = { _id: new ObjectId().toString(), buyerId: action.payload.buyerId, chatList: [{ buyerId: action.payload.buyerId, message: action.payload.message, isError: true }], hasMore: false, createdAt: new Date().toISOString(), updatedAt: new Date().toISOString() };
            if (state.getAllResult === null) {
                state.getAllResult = [newErrorChatDoc];
                return;
            }
            const isChatExist = state.getAllResult?.some?.((chatDoc) => chatDoc?.buyerId === action.payload.buyerId);
            if (isChatExist) {
                state.getAllResult = state.getAllResult?.map?.((chatDoc) => {
                    if (chatDoc?.buyerId === action.payload.buyerId) {
                        const chatList = chatDoc?.chatList ? [...new Map([...chatDoc?.chatList, newErrorChatDoc].map((v) => [v?._id, v])).values()] : [newErrorChatDoc];
                        return { ...chatDoc, chatList };
                    }
                    return chatDoc;
                });
                return;
            }
            state.getAllResult = [...new Map([{ buyerId: action.payload.buyerId, chatList: [newErrorChatDoc], hasMore: false, createdAt: new Date().toISOString(), updatedAt: new Date().toISOString() }, ...state.getAllResult].map((v) => [v?.buyerId, v])).values()];
        },
        getAllNextPage: (state, action) => {
            if (state.getAllResult === null) return;
            const isChatExist = state.getAllResult.some((chatDoc) => chatDoc?.buyerId === action.payload);
            if (isChatExist === false) return;
            state.getAllResult = state.getAllResult?.map?.((chatDoc) => (chatDoc?.buyerId === action.payload ? { ...chatDoc, chatIterator: chatDoc?.chatList?.[chatDoc?.chatList.length - 1]?.createdAt } : chatDoc));
        },
        patchOne: (state, action) => {
            if (state.getAllResult === null) {
                state.getAllResult = [{ buyerId: action.payload.buyerId, chatList: [action.payload], hasMore: false, createdAt: action.payload.createdAt, updatedAt: action.payload.updatedAt }];
                return;
            }
            const isChatExist = state.getAllResult?.some?.((chatDoc) => chatDoc?.buyerId === action.payload.buyerId);
            if (isChatExist) {
                state.getAllResult = state.getAllResult?.map?.((chatDoc) => {
                    if (chatDoc?.buyerId === action.payload.buyerId) {
                        const chatList = chatDoc?.chatList ? [...new Map([action.payload, ...chatDoc?.chatList].map((v) => [v?._id, v])).values()] : [action.payload];
                        return { ...chatDoc, chatList, updatedAt: chatList?.[0]?.updatedAt };
                    }
                    return chatDoc;
                });
                return;
            }
            state.getAllResult = [...new Map([{ buyerId: action.payload?.buyerId, chatList: [action.payload], hasMore: false, createdAt: action.payload?.createdAt, updatedAt: action.payload?.updatedAt }, ...state.getAllResult].map((v) => [v?.buyerId, v])).values()];
        },
        readOne: (state) => {
            if (state.getSellerUnreadChatResult - 1 < 0) return;
            state.getSellerUnreadChatResult -= 1;
        }
    }
});

export const chatAction = chatSlice.actions;
export const chatSelector = (state) => state.chat;
export const currentChatSelector = (state, buyerId) => state.chat.getAllResult?.find?.((ct) => ct?.buyerId === buyerId);
export default chatSlice.reducer;
