export const KEY_CODE_ENTER = 13;
export const KEY_CODE_COMMA = 188;

export const ROUTE_LIST = {
    superadmin: {
        pathname: 'superadmin',
        stringToColor: {
            pathname: 'string-to-color'
        }
    },
    register: {
        pathname: 'register'
    },
    login: {
        pathname: 'login'
    },
    product: {
        searchParams: {
            productSearch: 'product-search',
            productCategory: 'product-category',
            productId: 'product-id',
            productVariantId: 'product-variant-id',
            productUpsert: 'product-upsert'
        },
        params: {
            productId: 'productId'
        }
    },
    profile: {
        pathname: 'profile'
    },
    address: {
        pathname: 'address',
        searchParams: {
            addressIndex: 'address-index',
            addressUpdate: 'address-update'
        }
    },
    profileSeller: {
        pathname: 'profile-seller'
    },
    chat: {
        pathname: 'chat',
        searchParams: {
            chatPartnerId: 'chat-partner-id'
        }
    },
    emailAndPhoneNumberVerification: {
        pathname: 'email-and-phone-number-verification'
    },
    cart: {
        pathname: 'cart',
        shipment: {
            pathname: 'shipment'
        }
    },
    payment: {
        pathname: 'payment',
        params: {
            transactionId: 'transactionId'
        },
        // status: {
        //     pathname: 'status',
        //     searchParams: {
        //         orderId: 'order_id'
        //     }
        // }
    },
    orderList: {
        pathname: 'order-list',
        searchParams: {
            orderCreatedDate: 'order-created-date',
            orderStatus: 'order-status',
            searchOrder: 'search-order'
        }
    },
    invoice: {
        pathname: 'invoice',
        searchParams: {
            invoiceCode: 'invoice-code',
            printInvoice: 'print-invoice',
        }
    },
    orderLabelPrint: {
        pathname: 'order-label-print',
        searchParams: {
            invoiceCode: 'invoice-code',
        }
    },
};

export const LOCALSTORAGE = {
    rememberMe: {
        key: "remember_me",
        initialValue: "false",
        values: {
            false: "false",
            true: "true"
        },
    },
    appTheme: {
        key: "app_theme",
        initialValue: "light",
        values: {
            light: "light",
            dark: "dark",
        },
    },
    productDetailImageElementPosition: {
        key: "product_detail_image_element_position",
        initialValue: "sticky",
        value: {
            sticky: "sticky",
            static: "static",
        },
    },
    shippingAddressPanelElementPosition: {
        key: "shipping_address_panel_element_position",
        initialValue: 'static',
        value: {
            sticky: "sticky",
            static: "static",
        },
    },
    orderListPanelElementPosition: {
        key: "order_list_panel_element_position",
        initialValue: 'static',
        value: {
            sticky: "sticky",
            static: "static",
        },
    },
};

export const MAIN_API_URL_LOCAL = 'http://localhost:5000';
export const MAIN_API_URL_CLOUD = 'https://api.ludido.id';
export const MAIN_WEB_URL_LOCAL = 'http://localhost:3000';
export const MAIN_WEB_URL_CLOUD = 'https://ludido.id';
export const MAIN_API_VERSION = '/v1';

export const MAIN_API_URL = ['production', 'clouddev'].includes(process.env.REACT_APP_NODE_ENV) ? MAIN_API_URL_CLOUD + MAIN_API_VERSION : MAIN_API_URL_LOCAL + MAIN_API_VERSION;
export const MAIN_WEB_URL = ['production', 'clouddev'].includes(process.env.REACT_APP_NODE_ENV) ? MAIN_WEB_URL_CLOUD : MAIN_WEB_URL_LOCAL;
export const SOCKET_URL = ['production', 'clouddev'].includes(process.env.REACT_APP_NODE_ENV) ? MAIN_API_URL_CLOUD : MAIN_API_URL_LOCAL;
export const MIDTRANS_CLIENT_KEY = process.env.REACT_APP_NODE_ENV === 'production' ? process.env.REACT_APP_MIDTRANS_CLIENT_KEY_PRODUCTION : process.env.REACT_APP_MIDTRANS_CLIENT_KEY_DEVELOPMENT;
export const MIDTRANS_SNAP_URL = process.env.REACT_APP_NODE_ENV === 'production' ? 'https://app.midtrans.com/snap/snap.js' : 'https://app.sandbox.midtrans.com/snap/snap.js';
export const IMAGE_BASE_URL = `${MAIN_API_URL}/public/`;

export const indonesiaCoordinate = { lat: -2.473888688444448, lng: 117.88879998609853 };
export const indonesiaBound = { south: -22.667232559414682, west: 85.06165154859853, north: 18.02837509829607, east: 150.71594842359852 };

// export const APP_SERVICE_FEE = 2000;
// export const TRANSACTION_SERVICE_FEE = 1000;

export const EMPTY_STRING = '';

export const COLOR_PALETTE = {
    darkBlue1: '#091929',
    blueLink: '#39c',
    black2: '#2C2C2C',
    grey2: '#1E1E1E',
    lightBlue1: '#f2f3fc',
};

export const MUI_SNACKBAR_ANCHOR_ORIGIN = {
    'top-left': 'top-left',
    'top-center': 'top-center',
    'top-right': 'top-right',
    'bottom-left': 'bottom-left',
    'bottom-center': 'bottom-center',
    'bottom-right': 'bottom-right',
};

export const ROLES_LIST = {
    SuperAdmin: 'Super Admin',
    Buyer: 'Buyer',
    Seller: 'Seller'
};

export const MIDTRANS = {
    transactionStatus: {
        capture: 'capture',
        settlement: 'settlement',
        cancel: 'cancel',
        deny: 'deny',
        expire: 'expire',
        pending: 'pending'
    },
    fraudStatus: {
        challenge: 'challenge',
        accept: 'accept'
    },
    paymentStatus: {
        challenge: 'challenge',
        accept: 'accept',
        success: 'success',
        failure: 'failure',
        pending: 'pending',
    },
    payment: {
        credit_card: { type: 'credit_card', name: 'Card' },
        gopay: { type: 'gopay', name: 'GoPay' },
        qris: { type: 'qris', name: 'QRIS' },
        shopeepay: { type: 'shopeepay', name: 'Shopeepay' },
        bank_transfer: {
            type: 'bank_transfer',
            permata_va_number: {
                type: 'permata_va_number',
                name: 'Permata Virtual Account'
            },
            va_number: {
                bca: {
                    type: 'bca',
                    name: 'BCA Virtual Account'
                },
                bni: {
                    type: 'bni',
                    name: 'BNI Virtual Account'
                },
                bri: {
                    type: 'bri',
                    name: 'BRI Virtual Account'
                }
            }
        },
        echannel: { type: 'echannel', name: 'Mandiri Bill' },
        bca_klikpay: { type: 'bca_klikpay', name: 'BCA Klikpay' },
        bca_klikbca: { type: 'bca_klikbca', name: 'KlikBCA' },
        cimb_clicks: { type: 'cimb_clicks', name: 'CIMB Clicks' },
        danamon_online: { type: 'danamon_online', name: 'Danamon Online Banking' },
        cstore: {
            type: 'cstore',
            store: {
                indomaret: {
                    type: 'indomaret',
                    name: 'Indomaret'
                },
                alfamart: {
                    type: 'alfamart',
                    name: 'Alfamart'
                }
            }
        },
        akulaku: { type: 'akulaku', name: 'Akulaku' },
        bri_epay: { type: 'bri_epay', name: 'BRImo' }
    }
};

export const SELLER_ID = '6391c496d107143ec949f1ae';

export const USER_SCHEMA = {
    name: { minLength: 3, maxLength: 100 }, // mas kargo [char(200)] // paxel String, max: 100
    email: { maxLength: 100 }, // mas kargo [char(100)] // paxel String, max: 255
    phone: { minLength: 7, maxLength: 15 } // mas kargo [char(25)] // paxel String, min:7, max:15
};

export const ADDRESS_SCHEMA = {
    addressLabel: { maxLength: 30 },
    completeAddress: { maxLength: 200 }, // mas kargo [char(200)] // paxel String, max: 350
    province: { maxLength: 50 }, // mas kargo [char(200)] // paxel String, max: 50
    city: { maxLength: 50 }, // mas kargo [char(200)] // paxel String, max: 50
    district: { maxLength: 50 }, // mas kargo [char(200)] // paxel String, max: 50
    subdistrict: { maxLength: 50 }, // mas kargo [char(200)] // paxel String, max: 50
    postalCode: { maxLength: 5 }, // mas kargo [char(10)] // paxel String, max: 5
    addressCoordinate: {
        lat: { min: -90, max: 90 }, // paxel Number between:-90,90
        lng: { min: -180, max: 180 } // paxel Number between:-180,180
    }
};

export const PEMBELIAN_SCHEMA = {
    totalPrice: { max: 999_999_999, min: 10_000 }, // https://snap-docs.midtrans.com/#minimum-transaction-amount // paxel Float, between:1,999_999_999
    item: {
        nama: { maxLength: 200 }, // paxel String, max: 200
        kategori: { maxLength: 50 }, // paxel String, max: 50
        harga: { max: 999_999_999 }, // paxel String, between:1,999999999
        kuantitas: { max: 9_999 }, // paxel Int, between:1,9999
        bobot: { max: 9_999_999 }, // mas kargo [double(7,0)] // paxel Float, between:1,25000 || between:6000,20000
        panjang: { max: 9_999_999 }, // mas kargo [double(7,0)] // paxel Float, between:1,80 || between:1,50
        lebar: { max: 9_999_999 }, // mas kargo [double(7,0)] // paxel Float, between:1,80 || between:1,50
        tinggi: { max: 9_999_999 } // mas kargo [double(7,0)] // paxel Float, between:1,80 || between:1,50
    },
    invoiceNumber: { maxLength: 32 }
};

export const PEMBAYARAN = {
    Midtrans: 'midtrans',
    Lainnya: 'lainnya'
};

export const PAYMENT_STATUS = {
    challenge: 'challenge',
    accept: 'accept',
    success: 'success',
    failure: 'failure',
    pending: 'pending'
};

export const ORDER_STATUS_MENUNGGU_KONFIRMASI_OR_PESANAN_BARU = 'MKFPSB'; // Menunggu Konfirmasi atau Pesanan Baru
export const ORDER_STATUS_DIPROSES_OR_SIAP_DIKIRIM = 'DPSSDK'; // Diproses atau Siap Dikirim
export const ORDER_STATUS_DIKIRIM_OR_DALAM_PENGIRIMAN = 'DKRDRM'; // Dikirim atau Dalam Pengiriman
export const ORDER_STATUS_SAMPAI_TUJUAN = 'SAPTJA'; // Sampai Tujuan
export const ORDER_STATUS_BERHASIL_OR_PESANAN_SELESAI = 'BRSPSS'; // Berhasil atau Pesanan Selesai
export const ORDER_STATUS_TIDAK_BERHASIL_OR_DIBATALKAN_PENJUAL = 'TBRBTJ'; // Tidak Berhasil atau Dibatalkan Penjual

export const ORDER_STATUS_QUERY_PARAM_BUYER_BERLANGSUNG = "BLGSUG";

export const ORDER_STATUS_QUERY_PARAM = {
    semua: {
        code: 'semua',
        description: 'Semua',
    },
    berlangsung: {
        code: 'berlangsung',
        description: 'Berlangsung',
        sub: {
            menunggu_konfirmasi: {
                code: 'menunggu_konfirmasi', // 'pesanan_baru'
                description: 'Menunggu Konfirmasi', // 'Pesanan Baru'
            },
            diproses: {
                code: 'diproses', // 'siap_dikirim'
                description: 'Diproses', // 'Siap Dikirim'
            },
            dikirim: {
                code: 'dikirim', // 'dalam_pengiriman'
                description: 'Dikirim', // 'Dalam Pengiriman'
            },
            sampai_tujuan: {
                code: 'sampai_tujuan',
                description: 'Sampai Tujuan',
            },
        }
    },
    berhasil: {
        code: 'berhasil', // 'pesanan_selesai'
        description: 'Berhasil', // 'Pesanan Selesai'
    },
    tidak_berhasil: {
        code: 'tidak_berhasil', // 'dibatalkan'
        description: 'Tidak Berhasil', // 'Dibatalkan'
    }
};

export const ORDER_STATUS_BUYER = {
    [ORDER_STATUS_MENUNGGU_KONFIRMASI_OR_PESANAN_BARU]: 'Menunggu Konfirmasi',
    [ORDER_STATUS_DIPROSES_OR_SIAP_DIKIRIM]: 'Diproses',
    [ORDER_STATUS_DIKIRIM_OR_DALAM_PENGIRIMAN]: 'Dikirim',
    [ORDER_STATUS_SAMPAI_TUJUAN]: 'Sampai Tujuan',
    [ORDER_STATUS_BERHASIL_OR_PESANAN_SELESAI]: 'Selesai',
    [ORDER_STATUS_TIDAK_BERHASIL_OR_DIBATALKAN_PENJUAL]: 'Tidak berhasil',
};

export const ORDER_STATUS_SELLER = {
    [ORDER_STATUS_MENUNGGU_KONFIRMASI_OR_PESANAN_BARU]: 'Pesanan Baru',
    [ORDER_STATUS_DIPROSES_OR_SIAP_DIKIRIM]: 'Siap Dikirim',
    [ORDER_STATUS_DIKIRIM_OR_DALAM_PENGIRIMAN]: 'Dalam Pengiriman',
    [ORDER_STATUS_BERHASIL_OR_PESANAN_SELESAI]: 'Pesanan Selesai',
    [ORDER_STATUS_TIDAK_BERHASIL_OR_DIBATALKAN_PENJUAL]: 'Dibatalkan Penjual',
};

export const SOCKET_LISTENER = {
    "getUsers": "getUsers",
    "getChat": "getChat",
    "getNotificationSeller": "getNotificationSeller",
    "getNotificationBuyer": "getNotificationBuyer",
};

export const SOCKET_EMITTER = {
    "disconnect": "disconnect",
    "removeUser": "removeUser",
    "addUser": "addUser",
    "getOnlineUsers": "getOnlineUsers",
    "sendChat": "sendChat",
    "sendNotificationSeller": "sendNotificationSeller",
    "sendNotificationBuyer": "sendNotificationBuyer",
};
