import React, { lazy, Suspense } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';

const Shipment = lazy(() => import('../components/Shipment'));

const LazyShipment = () => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <Shipment />
        </Suspense>
    );
};

export default LazyShipment;
