import { Box } from '@mui/material';
import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import appLogo from '../images/ludido-logo.svg';

const RequireNoAuth = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Link to="/">
                <Box component="img" sx={{ height: '200px' }} alt="app-logo" src={appLogo} />
            </Link>
            <Outlet />
        </Box>
    );
};

export default RequireNoAuth;
