import React, { Suspense, lazy } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { ROLES_LIST } from '../configs/constants';
import { userAuthSelector } from '../redux/slices/userAuthSlice';
import { unsavedChangesAction, unsavedChangesSelector } from '../redux/slices/unsavedChangesSlice';
import LoadingSpinner from './LoadingSpinner';

const NavbarGuest = lazy(() => import('./NavbarGuest'));
const NavbarBuyer = lazy(() => import('./NavbarBuyer'));
const NavbarSeller = lazy(() => import('./NavbarSeller'));

const Layout = () => {
    const dispatch = useDispatch();
    const { authResult, authLoading } = useSelector(userAuthSelector);
    const { isAnyUnsavedChanges, openUnsavedChangesDialog } = useSelector(unsavedChangesSelector);

    const unsavedChangesDialogCancel = (evt) => {
        evt.preventDefault();
        dispatch(unsavedChangesAction.setOpenUnsavedChangesDialog(false));
    };

    return (
        <>
            {authResult?.authData?.role === ROLES_LIST.Buyer
                ? (
                    <Suspense fallback={<LoadingSpinner />}>
                        <NavbarBuyer />
                    </Suspense>
                ) : authResult?.authData?.role === ROLES_LIST.Seller
                    ? (
                        <Suspense fallback={<LoadingSpinner />}>
                            <NavbarSeller />
                        </Suspense>
                    ) : (
                        <Suspense fallback={<LoadingSpinner />}>
                            <NavbarGuest to="/" />
                        </Suspense>
                    )}
            {authLoading && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )}
            <Dialog open={isAnyUnsavedChanges && openUnsavedChangesDialog} onClose={unsavedChangesDialogCancel} aria-labelledby="unsaved-changes-dialog-title" aria-describedby="unsaved-changes-dialog-description">
                <DialogTitle id="unsaved-changes-dialog-title">
                    Perubahan belum disimpan
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="unsaved-changes-dialog-description">
                        Halo! Sepertinya Anda membuat suatu perubahan dan belum menyimpannya.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={unsavedChangesDialogCancel} variant="text" sx={{ '&:hover': { backgroundColor: 'transparent' } }}>OK</Button>
                </DialogActions>
            </Dialog>
            <Outlet />
        </>
    );
};

export default Layout;
