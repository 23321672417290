import { createSlice } from '@reduxjs/toolkit';
import isNotNullAndNotUndefined from '../../utils/isNotNullAndNotUndefined';

const initialState = {
    getAllNotificationSellerLoading: false,
    getAllNotificationSellerResult: null,
    getAllNotificationSellerError: null,

    getNotificationSellerDiprosesOrSiapDikirimResult: null,
};

export const notificationSellerSlice = createSlice({
    name: 'notificationSeller',
    initialState,
    reducers: {
        resetState: () => initialState,
        patchAllNotificationSellerSuccess: (state, action) => {
            if (isNotNullAndNotUndefined(action.payload) === false) return;
            // const notificationSellerData = { ...action.payload, read: false };
            if (state.getAllNotificationSellerResult === null) {
                state.getAllNotificationSellerResult = [action.payload];
                state.getAllNotificationSellerError = null;
                return;
            }
            const isNotificationSellerExist = state.getAllNotificationSellerResult?.some?.((notificationSeller) => notificationSeller?._id !== undefined && action.payload?._id !== undefined && notificationSeller?._id === action.payload?._id);
            if (isNotificationSellerExist === true) {
                state.getAllNotificationSellerResult = state.getAllNotificationSellerResult?.map?.((notificationSeller) => ((notificationSeller?._id !== undefined && action.payload?._id !== undefined && notificationSeller?._id === action.payload?._id) ? action.payload : notificationSeller));
                state.getAllNotificationSellerError = null;
                return;
            }
            if (isNotificationSellerExist === false) {
                state.getAllNotificationSellerResult = [action.payload, ...state.getAllNotificationSellerResult];
                state.getAllNotificationSellerError = null;
                return;
            }
        },
        readOneNotificationSeller: (state, action) => {
            if (isNotNullAndNotUndefined(state.getAllNotificationSellerResult) === false) return;
            state.getAllNotificationSellerResult = state.getAllNotificationSellerResult?.map?.((notificationSeller) => {
                if (notificationSeller?._id === action.payload) {
                    const notificationSellerLogsNew = notificationSeller?.logs?.map?.((notificationSellerLog) => (notificationSellerLog?.status === notificationSeller?.status ? { ...notificationSellerLog, read: { ...notificationSellerLog?.read, seller: true } } : notificationSellerLog));
                    const notificationSellerNew = { ...notificationSeller, logs: notificationSellerLogsNew };
                    return notificationSellerNew;
                }

                return notificationSeller;
            });
        },
        getAllNotificationSellerLoadingStart: (state) => {
            state.getAllNotificationSellerLoading = true;
        },
        getAllNotificationSellerLoadingEnd: (state) => {
            state.getAllNotificationSellerLoading = false;
        },
        getAllNotificationSellerSuccess: (state, action) => {
            if (isNotNullAndNotUndefined(action.payload) === false) return;
            state.getAllNotificationSellerResult = action.payload;
            state.getAllNotificationSellerError = null;
        },
        getAllNotificationSellerFailure: (state, action) => {
            state.getAllNotificationSellerError = action.payload;
        },
        filterNotificationSellerSuccess: (state, action) => {
            const isNotificationSellerExist = state.getAllNotificationSellerResult?.some?.((notificationSeller) => notificationSeller?._id !== undefined && action.payload !== undefined && notificationSeller?._id === action.payload);
            if (isNotificationSellerExist === true) {
                const getAllNotificationSellerResultNew = state.getAllNotificationSellerResult?.filter?.((notificationSeller) => notificationSeller?._id !== undefined && action.payload !== undefined && notificationSeller?._id !== action.payload);
                state.getAllNotificationSellerResult = getAllNotificationSellerResultNew?.length > 0 ? getAllNotificationSellerResultNew : null;
            }
            state.getAllNotificationSellerError = null;
        },

        // patchAllNotificationSellerDiprosesOrSiapDikirimSuccess: (state, action) => {
        //     if (isNotNullAndNotUndefined(action.payload) === false) return;
        //     if (state.getNotificationSellerDiprosesOrSiapDikirimResult === null) {
        //         state.getNotificationSellerDiprosesOrSiapDikirimResult = [action.payload];
        //         return;
        //     }
        //     const isNotificationSellerExist = state.getNotificationSellerDiprosesOrSiapDikirimResult?.some?.((notificationSeller) => notificationSeller?._id !== undefined && action.payload?._id !== undefined && notificationSeller?._id === action.payload?._id);
        //     if (isNotificationSellerExist === true) {
        //         state.getNotificationSellerDiprosesOrSiapDikirimResult = state.getNotificationSellerDiprosesOrSiapDikirimResult?.map?.((notificationSeller) => ((notificationSeller?._id !== undefined && action.payload?._id !== undefined && notificationSeller?._id === action.payload?._id) ? action.payload : notificationSeller));
        //         return;
        //     }
        //     if (isNotificationSellerExist === false) {
        //         state.getNotificationSellerDiprosesOrSiapDikirimResult = [action.payload, ...state.getNotificationSellerDiprosesOrSiapDikirimResult];
        //         return;
        //     }
        // },
        getAllNotificationSellerDiprosesOrSiapDikirimSuccess: (state, action) => {
            state.getNotificationSellerDiprosesOrSiapDikirimResult = action.payload;
            // state.getNotificationSellerDiprosesOrSiapDikirimError = null;
        },
        filterNotificationSellerDiprosesOrSiapDikirimSuccess: (state, action) => {
            const isNotificationSellerExist = state.getNotificationSellerDiprosesOrSiapDikirimResult?.some?.((notificationSeller) => notificationSeller?._id !== undefined && action.payload !== undefined && notificationSeller?._id === action.payload);
            if (isNotificationSellerExist === true) {
                const getNotificationSellerDiprosesOrSiapDikirimResultNew = state.getNotificationSellerDiprosesOrSiapDikirimResult?.filter?.((notificationSeller) => notificationSeller?._id !== undefined && action.payload !== undefined && notificationSeller?._id !== action.payload);
                state.getNotificationSellerDiprosesOrSiapDikirimResult = getNotificationSellerDiprosesOrSiapDikirimResultNew?.length > 0 ? getNotificationSellerDiprosesOrSiapDikirimResultNew : null;
            }
            // state.getNotificationSellerDiprosesOrSiapDikirimError = null;
        },
    }
});

export const notificationSellerAction = notificationSellerSlice.actions;
export const notificationSellerSelector = (state) => state.notificationSeller;
export default notificationSellerSlice.reducer;
