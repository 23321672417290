import React, { createContext, useState, useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { grey, blue } from '@mui/material/colors';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { CssBaseline } from '@mui/material';
import { LOCALSTORAGE } from '../configs/constants';
// import idLocale from 'date-fns/locale/id';

const getDesignTokens = (mode) => ({
    palette: {
        mode,
        ...(mode === 'dark'
            ? {
                // ? palette values for dark mode
                primary: {
                    main: blue[700]
                },
                text: {
                    primary: grey[500],
                },
            }
            : {
                // ? palette values for light mode
            }),
    },
});

export const ColorModeContext = createContext({ toggleColorMode: () => undefined, setColorMode: () => undefined });

const ThemeContextProvider = ({ children }) => {
    const [mode, setMode] = useState(() => (localStorage.getItem(LOCALSTORAGE.appTheme.key) === null ? 'light' : localStorage.getItem(LOCALSTORAGE.appTheme.key)));
    const colorMode = useMemo(() => ({
        setColorMode: (newMode) => {
            if (['light', 'dark'].includes(newMode)) {
                setMode(newMode);
                // localStorage.setItem(LOCALSTORAGE.appTheme.key, newMode);
                return;
            }
        },
        toggleColorMode: () => {
            setMode((prevMode) => {
                const nextMode = prevMode === 'light' ? 'dark' : 'light';
                localStorage.setItem(LOCALSTORAGE.appTheme.key, nextMode);
                return nextMode;
            });
        }
    }), []);

    // const theme = useMemo(() => createTheme({
    //     palette: {
    //         mode
    //     }
    // }), [mode]);

    const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                {/* <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={idLocale}> */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CssBaseline />
                    {children}
                </LocalizationProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};

export default ThemeContextProvider;
