import { createSlice } from '@reduxjs/toolkit';
import { ObjectId } from 'bson';
import { ROLES_LIST } from '../../configs/constants';

const initialState = {
    getBuyerConversationListAndUnreadChatLoading: true,
    getBuyerConversationListAndUnreadChatMessage: null,
    getBuyerConversationListResult: null,
    getBuyerNewChatResult: 0
};

export const conversationSlice = createSlice({
    name: 'conversation',
    initialState,
    reducers: {
        resetState: () => initialState,
        getBuyerConversationListAndUnreadChatStart: (state) => {
            state.getBuyerConversationListAndUnreadChatLoading = true;
        },
        getBuyerConversationListAndUnreadChatEnd: (state) => {
            state.getBuyerConversationListAndUnreadChatLoading = false;
        },
        getBuyerConversationListAndUnreadChatSuccess: (state, action) => {
            if (action.payload.conversationList === null) {
                state.getBuyerConversationListAndUnreadChatMessage = 'Belum ada chat.';
                return;
            }
            const newGetAllResultState = state.getBuyerConversationListResult === null ? [...action.payload.conversationList] : [...new Map([...state.getBuyerConversationListResult, ...action.payload.conversationList].map((v) => [v?.buyer?._id, v])).values()];
            state.getBuyerConversationListResult = newGetAllResultState;
            state.getBuyerConversationListAndUnreadChatMessage = newGetAllResultState ? null : 'Belum ada chat.';
            state.getBuyerNewChatResult = action.payload.newChat;
        },
        upsertOne: (state, action) => {
            state.getBuyerConversationListAndUnreadChatMessage = null;
            const incommingChat = { _id: new ObjectId().toString(), buyer: { ...action.payload?.buyerDocument }, createdAt: action.payload?.createdAt, updatedAt: action.payload?.updatedAt, __v: 0, sender: action.payload?.sender, recentChat: action.payload?.message, totalUnreadChat: 0 };
            if (state.getBuyerConversationListResult === null) {
                state.getBuyerConversationListResult = [incommingChat];
                state.getBuyerNewChatResult += 1;
                return;
            }
            const existingConversationDoc = state.getBuyerConversationListResult?.find?.((conv) => conv?.buyer?._id === action.payload?.buyerId);
            if (existingConversationDoc) {
                const filteredConversation = state.getBuyerConversationListResult.filter((conv) => conv?.buyer?._id !== action.payload?.buyerId);
                const newGetBuyerConversationListResultFlatten = [...new Map([{ ...existingConversationDoc, sender: action.payload?.sender, recentChat: action.payload?.message, totalUnreadChat: action.payload?.sender === ROLES_LIST.Buyer ? existingConversationDoc?.totalUnreadChat + 1 : existingConversationDoc?.totalUnreadChat }, ...filteredConversation]?.map?.((v) => [v?.buyer?._id, v]))?.values?.()];
                state.getBuyerConversationListResult = newGetBuyerConversationListResultFlatten;
                state.getBuyerNewChatResult = newGetBuyerConversationListResultFlatten.filter((conv) => conv?.sender === ROLES_LIST.Buyer && conv?.totalUnreadChat > 0).length;
                return;
            }
            const newGetBuyerConversationListResult = [...new Map([incommingChat, ...state.getBuyerConversationListResult]?.map?.((v) => [v?.buyer?._id, v]))?.values?.()];
            state.getBuyerConversationListResult = newGetBuyerConversationListResult;
            state.getBuyerNewChatResult = newGetBuyerConversationListResult.filter((conv) => conv?.sender === ROLES_LIST.Buyer && conv?.totalUnreadChat > 0).length;
        },

        setOnlineBuyer: (state, action) => {
            if (state.getBuyerConversationListResult === null) return;
            state.getBuyerConversationListResult = state.getBuyerConversationListResult.map((conv) => (action.payload?.some?.((onlineUser) => onlineUser?.userId === conv?.buyer?._id) ? { ...conv, buyer: { ...conv?.buyer, online: true } } : { ...conv, buyer: { ...conv?.buyer, online: false } }));
        },
        readOne: (state, action) => {
            if (state.getBuyerConversationListResult === null) return;
            state.getBuyerConversationListResult = state.getBuyerConversationListResult.map((conv) => (conv?.buyer?._id === action.payload ? { ...conv, totalUnreadChat: conv?.totalUnreadChat - 1 < 0 ? conv?.totalUnreadChat : conv?.totalUnreadChat - 1 } : conv));
            if (state.getBuyerNewChatResult - 1 < 0) return;
            state.getBuyerNewChatResult -= 1;
        }
    }
});

export const conversationAction = conversationSlice.actions;
export const conversationSelector = (state) => state.conversation;
export const selectedConversationSelector = (state, buyerId) => (state.conversation.getBuyerConversationListResult?.find?.((prt) => prt?.buyer?._id === buyerId) || null);
export default conversationSlice.reducer;
