import { createSlice } from '@reduxjs/toolkit';
import { ROLES_LIST } from '../../configs/constants';

const initialState = {
    getAllLoading: true,
    getAllResult: null,
    getAllError: null,
    postError: null,
    patchError: null,
    deleteError: null
};
export const userAddressSlice = createSlice({
    name: 'userAddress',
    initialState,
    reducers: {
        resetState: () => initialState,
        getAllStart: (state) => {
            state.getAllLoading = true;
        },
        getAllEnd: (state) => {
            state.getAllLoading = false;
        },
        getAllSuccess: (state, action) => {
            if (action.payload === null) return;
            state.getAllResult = action.payload;
            state.getAllError = null;
        },
        getAllFailure: (state, action) => {
            state.getAllError = action.payload;
        },
        postSuccess: (state, action) => {
            state.getAllResult = state.getAllResult === null ? [action.payload] : [...new Map([...state.getAllResult, action.payload]?.map?.((v) => [v?._id, v])).values()];
            state.postError = null;
        },
        postFailure: (state, action) => {
            state.postError = action.payload.message;
        },
        postFailureReset: (state) => {
            state.postError = null;
        },
        patchFailure: (state, action) => {
            state.patchError = { id: action.payload?.id, message: action.payload?.message };
        },
        patchFailureReset: (state) => {
            state.patchError = null;
        },
        deleteSuccess: (state, action) => {
            if (state.getAllResult === null) return;
            const userAddressDoc = state.getAllResult.find((address) => address?._id === action.payload);
            const getAllResultNew = state.getAllResult?.filter?.((address) => address?._id !== action.payload);
            if (getAllResultNew === undefined) return;
            state.getAllResult = getAllResultNew?.map?.((address, addressIndex) => {
                if (addressIndex === 0 && address?.userDetails?.role === ROLES_LIST.Buyer) {
                    return {
                        ...address,
                        isPrimary: userAddressDoc.isPrimary === true,
                        isShipping: userAddressDoc.isShipping === true,
                        // isBilling: userAddressDoc.isBilling === true
                    };
                }
                if (addressIndex === 0) return { ...address, isPrimary: userAddressDoc.isPrimary === true };
                return address;
            });
        },
        deleteFailure: (state, action) => {
            state.deleteError = { id: action.payload?.id, message: action.payload?.message };
        },
        deleteFailureReset: (state) => {
            state.deleteError = null;
        }
    }
});

export const userAddressAction = userAddressSlice.actions;
export const userAddressSelector = (state) => state.userAddress;
export const singleAlamatSelector = (state, addressIndex) => state.userAddress.getAllResult?.[addressIndex];
export default userAddressSlice.reducer;
