import { configureStore } from '@reduxjs/toolkit';
import product from './slices/productSlice';
import userAuth from './slices/userAuthSlice';
import userProfile from './slices/userProfileSlice';
import userAddress from './slices/userAddressSlice';
import conversation from './slices/conversationSlice';
import chat from './slices/chatSlice';
import cart from './slices/cartSlice';
import order from './slices/orderSlice';
import unsavedChanges from './slices/unsavedChangesSlice';
import sellerProfile from './slices/sellerProfileSlice';
import notificationSeller from './slices/notificationSellerSlice';
import notificationBuyer from './slices/notificationBuyerSlice';

export default configureStore({
    reducer: {
        product,
        userAuth,
        userProfile,
        userAddress,
        conversation,
        chat,
        cart,
        order,
        unsavedChanges,
        sellerProfile,
        notificationSeller,
        notificationBuyer,
    }
});
