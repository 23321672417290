import React from 'react';
import { useTheme, Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Unauthorized = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const goHome = () => navigate('/');

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '8px', padding: '8px', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <Typography variant="body1" component="h1" sx={{ fontWeight: 'bold', textAlign: 'center' }}>Anda tidak memilik akses untuk halaman yang Anda minta.</Typography>
            <Button onClick={goHome} variant="text" sx={{ color: theme.palette.text.primary, '&:hover': { backgroundColor: 'transparent' } }}>Ke Halaman Home</Button>
        </Box>
    );
};

export default Unauthorized;
