import { createSlice } from '@reduxjs/toolkit';
import isNan from '../../utils/isNan';

const initialState = {
    getAllLoading: true,
    getAllResult: null,
    getAllResultDisabled: null,
    getAllMessage: null,
    getAllError: null,

    totalCheckedItem: 0,
    totalGrossPrice: 0,
    totalDiscountPrice: 0,
    totalNetPrice: 0,

    isAnyInvalidCartItem: false,

    getAllCheckedLoading: true,
    getAllCheckedResult: null,
    getAllCheckedError: null,
    getShippingAddressResult: null,
    getAllCheckedDeliverySericesSicepat: null,
    getAllCheckedDeliverySericesSicepatProductsWithDeliveryDiscountFee: null,

    getAllCheckedDeliveryFee: null,
    getAllCheckedCartItemsSelectedSicepatDeliveryService: null,
    getAllCheckedCartItemsSelectedSicepatDeliveryServiceDescription: null,

    totalItem: 0,
    totalCheckedGrossPrice: 0,
    totalCheckedDiscountPrice: 0,
    totalCheckedNetPrice: 0,
    totalCheckedWeightInKiloGram: 0,
    totalShippingCost: 0,

    sellerPrimaryAddress: null,

    postSuccess: null,
    postError: null
};

const getCartSummary = (cartItemList) => {
    const summary = cartItemList.reduce((summaryResult, cartItem) => {
        if (isNan(cartItem.quantity)) return { totalCheckedItem: summaryResult.totalCheckedItem, totalGrossPrice: summaryResult.totalGrossPrice, totalDiscountPrice: summaryResult.totalDiscountPrice, totalNetPrice: summaryResult.totalNetPrice };
        if (cartItem.checked === false) return { totalCheckedItem: summaryResult.totalCheckedItem, totalGrossPrice: summaryResult.totalGrossPrice, totalDiscountPrice: summaryResult.totalDiscountPrice, totalNetPrice: summaryResult.totalNetPrice };
        const totalGrossPriceCurrent = cartItem.variant.price * cartItem.quantity;
        const totalDiscountPriceCurrent = cartItem.variant.discount > 0 ? (cartItem.variant.price * (cartItem.variant.discount / 100)) * cartItem.quantity : 0;
        const totalNetPriceCurrent = totalGrossPriceCurrent - totalDiscountPriceCurrent;

        return { totalCheckedItem: summaryResult.totalCheckedItem + cartItem.quantity, totalGrossPrice: summaryResult.totalGrossPrice + totalGrossPriceCurrent, totalDiscountPrice: summaryResult.totalDiscountPrice + totalDiscountPriceCurrent, totalNetPrice: summaryResult.totalNetPrice + totalNetPriceCurrent };
    }, { totalCheckedItem: 0, totalGrossPrice: 0, totalDiscountPrice: 0, totalNetPrice: 0 });
    return { ...summary };
};

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        resetState: () => initialState,
        getAllStart: (state) => {
            state.getAllLoading = true;
        },
        getAllEnd: (state) => {
            state.getAllLoading = false;
        },
        getAllSuccess: (state, action) => {
            const isAnyInvalidCartItemNew = action.payload?.cartItems?.some?.((cartItem) => cartItem?.checked === true && cartItem?.errorMessage !== undefined);
            state.isAnyInvalidCartItem = isAnyInvalidCartItemNew === undefined ? false : isAnyInvalidCartItemNew;
            state.getAllResult = action.payload?.cartItems;
            state.getAllResultDisabled = action.payload?.cartItemsDisabled;
            state.getAllMessage = (action.payload?.cartItems?.length > 0 || action.payload?.cartItemsDisabled?.length > 0) ? null : 'Keranjang kosong.';
            state.totalCheckedItem = action.payload?.totalCheckedItem;
            state.totalGrossPrice = action.payload?.totalGrossPrice;
            state.totalDiscountPrice = action.payload?.totalDiscountPrice;
            state.totalNetPrice = action.payload?.totalNetPrice;
            state.getAllError = null;
        },
        getAllFailure: (state, action) => {
            state.getAllError = action.payload;
        },
        postSuccess: (state, action) => {
            state.postSuccess = action.payload.message;
            state.postError = null;
        },
        postError: (state, action) => {
            state.postError = action.payload;
        },
        postReset: (state) => {
            state.postSuccess = null;
            state.postError = null;
        },
        updateQuantity: (state, action) => {
            if (state.getAllResult === null) return;
            const newGetAllResult = state.getAllResult?.map?.((cartItem) => (cartItem?._id === action.payload?._id ? { ...cartItem, quantity: action.payload?.quantity } : cartItem));
            const isAnyInvalidCartItemNew = newGetAllResult?.some?.((cartItem) => cartItem?.checked === true && cartItem?.errorMessage !== undefined);
            state.isAnyInvalidCartItem = isAnyInvalidCartItemNew === undefined ? false : isAnyInvalidCartItemNew;
            state.getAllResult = newGetAllResult;
            const summary = getCartSummary(newGetAllResult);
            state.totalCheckedItem = summary.totalCheckedItem;
            state.totalGrossPrice = summary.totalGrossPrice;
            state.totalDiscountPrice = summary.totalDiscountPrice;
            state.totalNetPrice = summary.totalNetPrice;

            state.totalCheckedGrossPrice = 0;
            state.totalCheckedDiscountPrice = 0;
            state.totalCheckedNetPrice = 0;
            state.totalShippingCost = 0;
        },
        updateCheck: (state, action) => {
            if (state.getAllResult === null) return;
            const newGetAllResult = state.getAllResult?.map?.((cartItem) => (cartItem?._id === action.payload?._id ? { ...cartItem, checked: action.payload?.checked } : cartItem));
            const isAnyInvalidCartItemNew = newGetAllResult?.some?.((cartItem) => cartItem?.checked === true && cartItem?.errorMessage !== undefined);
            state.isAnyInvalidCartItem = isAnyInvalidCartItemNew === undefined ? false : isAnyInvalidCartItemNew;
            state.getAllResult = newGetAllResult;
            const summary = getCartSummary(newGetAllResult);
            state.totalCheckedItem = summary.totalCheckedItem;
            state.totalGrossPrice = summary.totalGrossPrice;
            state.totalDiscountPrice = summary.totalDiscountPrice;
            state.totalNetPrice = summary.totalNetPrice;

            state.totalCheckedGrossPrice = 0;
            state.totalCheckedDiscountPrice = 0;
            state.totalCheckedNetPrice = 0;
            state.totalShippingCost = 0;
        },
        updateOne: (state, action) => {
            if (state.getAllResult === null) return;
            if (action.payload?.variant?.stock === 0) {
                const newGetAllResult = state.getAllResult?.filter?.((cartItem) => cartItem?._id !== action.payload?._id);
                state.getAllResultDisabled = (state.getAllResultDisabled === null || state.getAllResultDisabled?.some?.((cartItem) => cartItem?._id === action.payload?._id) === true) ? [action.payload] : [...state.getAllResultDisabled, action.payload];

                if (newGetAllResult?.length === 0) {
                    state.isAnyInvalidCartItem = false;
                    state.getAllResult = null;
                    state.totalGrossPrice = 0;
                    state.totalDiscountPrice = 0;
                    state.totalNetPrice = 0;
                    state.getAllMessage = state.getAllResult?.length > 0 ? null : 'Keranjang kosong.';
                    return;
                }

                const isAnyInvalidCartItemNew = newGetAllResult?.some?.((cartItem) => cartItem?.checked === true && cartItem?.errorMessage !== undefined);
                state.isAnyInvalidCartItem = isAnyInvalidCartItemNew === undefined ? false : isAnyInvalidCartItemNew;
                state.getAllResult = newGetAllResult;
                const summary = getCartSummary(newGetAllResult);
                state.totalCheckedItem = summary.totalCheckedItem;
                state.totalGrossPrice = summary.totalGrossPrice;
                state.totalDiscountPrice = summary.totalDiscountPrice;
                state.totalNetPrice = summary.totalNetPrice;

                state.totalCheckedGrossPrice = 0;
                state.totalCheckedDiscountPrice = 0;
                state.totalCheckedNetPrice = 0;
                state.totalShippingCost = 0;
                return;
            }

            const newGetAllResult = state.getAllResult?.map?.((cartItem) => (cartItem?._id === action.payload?._id ? action.payload : cartItem));
            const isAnyInvalidCartItemNew = newGetAllResult?.some?.((cartItem) => cartItem?.checked === true && cartItem?.errorMessage !== undefined);
            state.isAnyInvalidCartItem = isAnyInvalidCartItemNew === undefined ? false : isAnyInvalidCartItemNew;
            state.getAllResult = newGetAllResult;
            const summary = getCartSummary(newGetAllResult);
            state.totalCheckedItem = summary.totalCheckedItem;
            state.totalGrossPrice = summary.totalGrossPrice;
            state.totalDiscountPrice = summary.totalDiscountPrice;
            state.totalNetPrice = summary.totalNetPrice;

            state.totalCheckedGrossPrice = 0;
            state.totalCheckedDiscountPrice = 0;
            state.totalCheckedNetPrice = 0;
            state.totalShippingCost = 0;
        },
        upsertNote: (state, action) => {
            if (state.getAllResult === null) return;
            state.getAllResult = state.getAllResult?.map?.((cartItem) => (cartItem?._id === action.payload?._id ? { ...cartItem, optionalNote: action.payload?.optionalNote } : cartItem));
        },
        deleteChecked: (state) => {
            if (state.getAllResult === null) return;
            const newGetAllResult = state.getAllResult?.filter?.((cartItem) => (cartItem?.checked === false));

            if (newGetAllResult?.length === 0) {
                state.isAnyInvalidCartItem = false;
                state.getAllResult = null;
                state.totalGrossPrice = 0;
                state.totalDiscountPrice = 0;
                state.totalNetPrice = 0;
                state.getAllMessage = state.getAllResultDisabled?.length > 0 ? null : 'Keranjang kosong.';
                return;
            }

            const isAnyInvalidCartItemNew = newGetAllResult?.some?.((cartItem) => cartItem?.checked === true && cartItem?.errorMessage !== undefined);
            state.isAnyInvalidCartItem = isAnyInvalidCartItemNew === undefined ? false : isAnyInvalidCartItemNew;
            state.getAllResult = newGetAllResult;
            const summary = getCartSummary(newGetAllResult);
            state.totalCheckedItem = summary.totalCheckedItem;
            state.totalGrossPrice = summary.totalGrossPrice;
            state.totalDiscountPrice = summary.totalDiscountPrice;
            state.totalNetPrice = summary.totalNetPrice;

            state.totalCheckedGrossPrice = 0;
            state.totalCheckedDiscountPrice = 0;
            state.totalCheckedNetPrice = 0;
            state.totalShippingCost = 0;
        },
        deleteDisabled: (state) => {
            state.getAllResultDisabled = null;
            state.getAllMessage = state.getAllResult?.length > 0 ? null : 'Keranjang kosong.';
        },
        deleteOne: (state, action) => {
            if (state.getAllResult === null) return;
            const newGetAllResult = state.getAllResult?.filter?.((cartItem) => (cartItem?._id !== action.payload));

            if (newGetAllResult?.length === 0) {
                state.isAnyInvalidCartItem = false;
                state.getAllResult = null;
                state.totalGrossPrice = 0;
                state.totalDiscountPrice = 0;
                state.totalNetPrice = 0;
                state.getAllMessage = state.getAllResultDisabled?.length > 0 ? null : 'Keranjang kosong.';
                return;
            }

            const isAnyInvalidCartItemNew = newGetAllResult?.some?.((cartItem) => cartItem?.checked === true && cartItem?.errorMessage !== undefined);
            state.isAnyInvalidCartItem = isAnyInvalidCartItemNew === undefined ? false : isAnyInvalidCartItemNew;
            state.getAllResult = newGetAllResult;
            const summary = getCartSummary(newGetAllResult);
            state.totalCheckedItem = summary.totalCheckedItem;
            state.totalGrossPrice = summary.totalGrossPrice;
            state.totalDiscountPrice = summary.totalDiscountPrice;
            state.totalNetPrice = summary.totalNetPrice;

            state.totalCheckedGrossPrice = 0;
            state.totalCheckedDiscountPrice = 0;
            state.totalCheckedNetPrice = 0;
            state.totalShippingCost = 0;
        },

        deleteOneDisabled: (state, action) => {
            if (state.getAllResultDisabled === null) return;
            const newGetAllResultDisabled = state.getAllResultDisabled?.filter?.((cartItem) => (cartItem?._id !== action.payload));
            if (newGetAllResultDisabled?.length === 0) {
                state.getAllResultDisabled = null;
                state.getAllMessage = state.getAllResult?.length > 0 ? null : 'Keranjang kosong.';
                return;
            }
            state.getAllResultDisabled = newGetAllResultDisabled;
        },

        getAllCheckedStart: (state) => {
            state.getAllCheckedLoading = true;
        },
        getAllCheckedEnd: (state) => {
            state.getAllCheckedLoading = false;
        },
        getAllCheckedFailure: (state, action) => {
            state.getAllCheckedError = action.payload;
        },
        getAllCheckedSuccess: (state, action) => {
            state.getShippingAddressResult = action.payload?.shippingAddress;
            state.getAllCheckedResult = action.payload?.cartItems;
            state.totalItem = action.payload?.totalItem;
            state.totalCheckedGrossPrice = action.payload?.totalGrossPrice;
            state.totalCheckedDiscountPrice = action.payload?.totalDiscountPrice;
            state.totalCheckedNetPrice = action.payload?.totalNetPrice;
            state.totalCheckedWeightInKiloGram = action.payload?.totalWeightInKiloGram;
            state.getAllCheckedDeliveryFee = null;
            state.getAllCheckedCartItemsSelectedSicepatDeliveryService = null;
            state.getAllCheckedCartItemsSelectedSicepatDeliveryServiceDescription = null;
            state.getAllCheckedDeliverySericesSicepat = action.payload?.deliveryServicesSicepat;
            state.getAllCheckedDeliverySericesSicepatProductsWithDeliveryDiscountFee = action.payload?.deliveryServicesSicepatProductsWithDeliveryDiscountFee;
            state.sellerPrimaryAddress = action.payload?.sellerPrimaryAddress;
            state.getAllCheckedError = null;
        },
        setAllCheckedCartItemsDeliveryService: (state, action) => {
            state.getAllCheckedDeliveryFee = action.payload.deliveryFee;
            state.getAllCheckedDeliveryFeeDiscount = ((state.getAllCheckedDeliverySericesSicepatProductsWithDeliveryDiscountFee === null) ? 0 : state.getAllCheckedDeliverySericesSicepatProductsWithDeliveryDiscountFee?.find?.((tariff) => (tariff?.service === action.payload.service))?.tariff);
            state.getAllCheckedCartItemsSelectedSicepatDeliveryService = action.payload.service;
            state.getAllCheckedCartItemsSelectedSicepatDeliveryServiceDescription = action.payload.description;
        },
        updateShippingAddressSuccess: (state, action) => {
            state.getShippingAddressResult = action.payload;
        }
    }
});

export const cartAction = cartSlice.actions;
export const cartSelector = (state) => state.cart;
export default cartSlice.reducer;
