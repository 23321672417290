import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    getOneLoading: true,
    getOneResult: null,
    isDataExist: null,
    getOneError: null,
    updateError: null
};
export const sellerProfileSlice = createSlice({
    name: 'sellerProfile',
    initialState,
    reducers: {
        resetState: () => initialState,
        getOneStart: (state) => {
            state.getOneLoading = true;
        },
        getOneEnd: (state) => {
            state.getOneLoading = false;
        },
        getOneSuccess: (state, action) => {
            const getOneResultNew = action.payload || null;
            state.getOneResult = getOneResultNew;
            state.isDataExist = getOneResultNew !== null;
            state.getOneError = null;
        },
        getOneFailure: (state, action) => {
            state.getOneError = action.payload;
        },
        updateFailure: (state, action) => {
            state.updateError = action.payload;
        },
        updateFailureReset: (state) => {
            state.updateError = null;
        }
    }
});

export const sellerProfileAction = sellerProfileSlice.actions;
export const sellerProfileSelector = (state) => state.sellerProfile;
export default sellerProfileSlice.reducer;
