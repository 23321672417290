import { Container } from '@mui/material';
import React, { lazy, Suspense } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';

const UserLoginForm = lazy(() => import('../components/UserLoginForm'));

const LazyUserLogin = () => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <Container component="div" maxWidth="sm">
                <UserLoginForm />
            </Container>
        </Suspense>
    );
};

export default LazyUserLogin;
