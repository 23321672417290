import React, { lazy, Suspense } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';

const OrderListBuyerWaitingPayment = lazy(() => import('../components/OrderListBuyerWaitingPayment'));

const LazyOrderListBuyerWaitingPayment = () => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <OrderListBuyerWaitingPayment />
        </Suspense>
    );
};

export default LazyOrderListBuyerWaitingPayment;
