import React, { lazy, Suspense } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';

const Invoice = lazy(() => import('../components/Invoice'));

const LazyInvoice = () => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <Invoice />
        </Suspense>
    );
};

export default LazyInvoice;
