import { createSlice } from '@reduxjs/toolkit';
import isNotNullAndNotUndefined from '../../utils/isNotNullAndNotUndefined';

const initialState = {
    getAllNotificationBuyerLoading: false,
    getAllNotificationBuyerResult: null,
    getAllNotificationBuyerError: null,

    getNotificationBuyerDiprosesOrSiapDikirimResult: null,
    getNotificationBuyerDikirimOrDalamPengirimanResult: null,
};

export const notificationBuyerSlice = createSlice({
    name: 'notificationBuyer',
    initialState,
    reducers: {
        resetState: () => initialState,
        patchAllNotificationBuyerSuccess: (state, action) => {
            if (isNotNullAndNotUndefined(action.payload) === false) return;
            // const notificationBuyerData = { ...action.payload, read: false };
            if (state.getAllNotificationBuyerResult === null) {
                state.getAllNotificationBuyerResult = [action.payload];
                state.getAllNotificationBuyerError = null;
                return;
            }
            const isNotificationBuyerExist = state.getAllNotificationBuyerResult?.some?.((notificationBuyer) => notificationBuyer?._id !== undefined && action.payload?._id !== undefined && notificationBuyer?._id === action.payload?._id);
            if (isNotificationBuyerExist === true) {
                state.getAllNotificationBuyerResult = state.getAllNotificationBuyerResult?.map?.((notificationBuyer) => ((notificationBuyer?._id !== undefined && action.payload?._id !== undefined && notificationBuyer?._id === action.payload?._id) ? action.payload : notificationBuyer));
                state.getAllNotificationBuyerError = null;
                return;
            }
            if (isNotificationBuyerExist === false) {
                state.getAllNotificationBuyerResult = [action.payload, ...state.getAllNotificationBuyerResult];
                state.getAllNotificationBuyerError = null;
                return;
            }
        },
        readOneNotificationBuyer: (state, action) => {
            if (isNotNullAndNotUndefined(state.getAllNotificationBuyerResult) === false) return;
            state.getAllNotificationBuyerResult = state.getAllNotificationBuyerResult?.map?.((notificationBuyer) => {
                if (notificationBuyer?._id === action.payload) {
                    const notificationBuyerLogsNew = notificationBuyer?.logs?.map?.((notificationBuyerLog) => (notificationBuyerLog?.status === notificationBuyer?.status ? { ...notificationBuyerLog, read: { ...notificationBuyerLog?.read, buyer: true } } : notificationBuyerLog));
                    const notificationBuyerNew = { ...notificationBuyer, logs: notificationBuyerLogsNew };
                    return notificationBuyerNew;
                }

                return notificationBuyer;
            });
        },
        getAllNotificationBuyerLoadingStart: (state) => {
            state.getAllNotificationBuyerLoading = true;
        },
        getAllNotificationBuyerLoadingEnd: (state) => {
            state.getAllNotificationBuyerLoading = false;
        },
        getAllNotificationBuyerSuccess: (state, action) => {
            if (isNotNullAndNotUndefined(action.payload) === false) return;
            state.getAllNotificationBuyerResult = action.payload;
            state.getAllNotificationBuyerError = null;
        },
        getAllNotificationBuyerFailure: (state, action) => {
            state.getAllNotificationBuyerError = action.payload;
        },
        filterNotificationBuyerSuccess: (state, action) => {
            const isNotificationBuyerExist = state.getAllNotificationBuyerResult?.some?.((notificationBuyer) => notificationBuyer?._id !== undefined && action.payload !== undefined && notificationBuyer?._id === action.payload);
            if (isNotificationBuyerExist === true) {
                const getAllNotificationBuyerResultNew = state.getAllNotificationBuyerResult?.filter?.((notificationBuyer) => notificationBuyer?._id !== undefined && action.payload !== undefined && notificationBuyer?._id !== action.payload);
                state.getAllNotificationBuyerResult = getAllNotificationBuyerResultNew?.length > 0 ? getAllNotificationBuyerResultNew : null;
            }
            state.getAllNotificationBuyerError = null;
        },

        getAllNotificationBuyerDiprosesOrSiapDikirimSuccess: (state, action) => {
            state.getNotificationBuyerDiprosesOrSiapDikirimResult = action.payload;
        },
        filterNotificationBuyerDiprosesOrSiapDikirimSuccess: (state, action) => {
            const isNotificationBuyerExist = state.getNotificationBuyerDiprosesOrSiapDikirimResult?.some?.((notificationBuyer) => notificationBuyer?._id !== undefined && action.payload !== undefined && notificationBuyer?._id === action.payload);
            if (isNotificationBuyerExist === true) {
                const getNotificationBuyerDiprosesOrSiapDikirimResultNew = state.getNotificationBuyerDiprosesOrSiapDikirimResult?.filter?.((notificationBuyer) => notificationBuyer?._id !== undefined && action.payload !== undefined && notificationBuyer?._id !== action.payload);
                state.getNotificationBuyerDiprosesOrSiapDikirimResult = getNotificationBuyerDiprosesOrSiapDikirimResultNew?.length > 0 ? getNotificationBuyerDiprosesOrSiapDikirimResultNew : null;
            }
        },

        getAllNotificationBuyerDikirimOrDalamPengirimanSuccess: (state, action) => {
            state.getNotificationBuyerDikirimOrDalamPengirimanResult = action.payload;
        },
        pushNotificationBuyerDikirimOrDalamPengirimanSuccess: (state, action) => {
            if (state.getNotificationBuyerDikirimOrDalamPengirimanResult === null) {
                state.getNotificationBuyerDikirimOrDalamPengirimanResult = [action.payload];
                return;
            }
            if (state.getNotificationBuyerDikirimOrDalamPengirimanResult?.some?.((notificationBuyer) => notificationBuyer?._id !== undefined && action.payload !== undefined && notificationBuyer?._id === action.payload)) return;
            state.getNotificationBuyerDikirimOrDalamPengirimanResult = [...state.getNotificationBuyerDikirimOrDalamPengirimanResult, action.payload];
        },
    }
});

export const notificationBuyerAction = notificationBuyerSlice.actions;
export const notificationBuyerSelector = (state) => state.notificationBuyer;
export default notificationBuyerSlice.reducer;
