import { signOut, signInWithEmailAndPassword } from 'firebase/auth';
import { MAIN_WEB_URL_CLOUD, ROUTE_LIST, SOCKET_EMITTER } from '../../configs/constants';
import { auth } from '../../firebase/firebase';
import axios from '../../axios/axios';
import { productAction } from '../slices/productSlice';
import { userAddressAction } from '../slices/userAddressSlice';
import { userProfileAction } from '../slices/userProfileSlice';
import { userAuthAction } from '../slices/userAuthSlice';
import { conversationAction } from '../slices/conversationSlice';
import { chatAction } from '../slices/chatSlice';
import { cartAction } from '../slices/cartSlice';
import { orderAction } from '../slices/orderSlice';
import { unsavedChangesAction } from '../slices/unsavedChangesSlice';
import { notificationBuyerAction } from '../slices/notificationBuyerSlice';
import { notificationSellerAction } from '../slices/notificationSellerSlice';
import { sellerProfileAction } from '../slices/sellerProfileSlice';

export const userLogin = (navigate, from, data, socket) => async (dispatch) => {
    try {
        dispatch(userAuthAction.authLoadingStart());
        const { data: { authData, accessToken } } = await axios.post('/login', data, { headers: { 'Content-Type': 'application/json' }, withCredentials: true });
        socket.emit(SOCKET_EMITTER.addUser, { userId: authData?._id, userRole: authData?.role });
        await signInWithEmailAndPassword(auth, data.email, data.password);
        dispatch(userAuthAction.loginSuccess({ authData, accessToken }));
        navigate(from?.search ? from.pathname + from.search : from.pathname, { replace: true });
    } catch (err) {
        console.log('error @ userLogin', err);
        if (err?.code === 'auth/user-not-found') {
            dispatch(userAuthAction.loginFailure('Akun tidak terdaftar.'));
            return;
        }
        if (err?.code === 'auth/wrong-password') {
            dispatch(userAuthAction.loginFailure('Kata sandi salah.'));
            return;
        }
        if (err?.code === 'auth/invalid-email') {
            dispatch(userAuthAction.loginFailure(`Akun dengan email ${data.email} belum terdaftar.`));
            return;
        }
        if (err?.code === 'auth/user-disabled') {
            dispatch(userAuthAction.loginFailure('Akses ke akun ini telah dinonaktifkan untuk sementara karena banyak upaya login yang gagal. Anda dapat segera memulihkannya dengan mengatur ulang kata sandi Anda atau Anda dapat mencoba lagi nanti.'));
            return;
        }
        dispatch(userAuthAction.loginFailure(err.response?.data?.message || err.message));
    } finally {
        dispatch(userAuthAction.authLoadingEnd());
    }
};

export const userRegister = (navigate, data, socket, emptyField) => async (dispatch) => {
    try {
        dispatch(userAuthAction.authLoadingStart());
        const { data: { authData, accessToken } } = await axios.post('/register', data, { headers: { 'Content-Type': 'application/json' }, withCredentials: true });
        await signInWithEmailAndPassword(auth, data.email, data.password);
        emptyField();
        socket.emit(SOCKET_EMITTER.addUser, { userId: authData?._id, userRole: authData?.role });
        dispatch(userAuthAction.loginSuccess({ authData, accessToken }));
        navigate('/' + ROUTE_LIST.profile.pathname, { replace: true });
    } catch (err) {
        console.log('error @ userRegister', err);
        if (!err?.response) dispatch(userAuthAction.registerFailure({ error: 'Terjadi kesalahan.' }));
        dispatch(userAuthAction.registerFailure({ error: err?.response?.data?.message || err.message }));
    } finally {
        dispatch(userAuthAction.authLoadingEnd());
    }
};

export const authorizedLogout = (navigate, location, socket) => async (dispatch) => {
    try {
        dispatch(userAuthAction.authLoadingStart());

        dispatch(userProfileAction.resetState());
        dispatch(userAddressAction.resetState());
        dispatch(productAction.resetState());
        dispatch(conversationAction.resetState());
        dispatch(chatAction.resetState());
        dispatch(cartAction.resetState());
        dispatch(orderAction.resetState());
        dispatch(sellerProfileAction.resetState());
        dispatch(unsavedChangesAction.resetState());
        dispatch(notificationBuyerAction.resetState());
        dispatch(notificationSellerAction.resetState());

        await signOut(auth);
        socket.emit(SOCKET_EMITTER.removeUser);
        await axios.get('/logout', { withCredentials: true });
        dispatch(userAuthAction.resetState());
        if (process.env.REACT_APP_NODE_ENV === 'production') {
            window.location.href = MAIN_WEB_URL_CLOUD;
            return;
        }
        navigate('/' + ROUTE_LIST.login.pathname, { state: { from: { ...location, state: null } }, replace: true });
    } catch (err) {
        console.log('error @ authorizedLogout', err);
    } finally {
        dispatch(userAuthAction.authLoadingEnd());
    }
};

export const userReauth = (socket) => async (dispatch) => {
    try {
        dispatch(userAuthAction.authLoadingStart());
        const { data: { authData, accessToken } } = await axios.get('/refresh-token?includeUserData=true', { withCredentials: true });
        socket.emit(SOCKET_EMITTER.addUser, { userId: authData?._id, userRole: authData?.role });
        dispatch(userAuthAction.setAuthResult({ authData, accessToken }));
    } catch (err) {
        console.log('error getting new refresh token');
        if (err.response?.status === 401) {
            console.log('token not found');
            return;
        }
        if (err.response?.status === 403) {
            console.log('token not valid or expired or not attached to any user in database or decoded token not match with user found in database');
            return;
        }
    } finally {
        dispatch(userAuthAction.authLoadingEnd());
    }
};
