import React, { lazy, Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ROUTE_LIST } from '../configs/constants';
import LoadingSpinner from '../components/LoadingSpinner';

const ProductListGetAll = lazy(() => import('../components/ProductListGetAll'));
const ProductListGetBySearch = lazy(() => import('../components/ProductListGetBySearch'));
const ProductListGetByCategory = lazy(() => import('../components/ProductListGetByCategory'));

const LazyHome = () => {
    const [searchParams] = useSearchParams();
    const cari = searchParams.get(ROUTE_LIST.product.searchParams.productSearch);
    const kategori = searchParams.get(ROUTE_LIST.product.searchParams.productCategory);

    if (cari && kategori === null) {
        return (
            <Suspense fallback={<LoadingSpinner />}>
                <ProductListGetBySearch />
            </Suspense>
        );
    }

    if (kategori && cari === null) {
        return (
            <Suspense fallback={<LoadingSpinner />}>
                <ProductListGetByCategory />
            </Suspense>
        );
    }

    return (
        <Suspense fallback={<LoadingSpinner />}>
            <ProductListGetAll />
        </Suspense>
    );
};

export default LazyHome;
