import React, { lazy, Suspense } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';

const UserAddress = lazy(() => import('../components/UserAddress'));

const LazyUserAddress = () => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <UserAddress />
        </Suspense>
    );
};

export default LazyUserAddress;
