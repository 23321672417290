import React, { lazy, Suspense } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';

const ProductDetail = lazy(() => import('../components/ProductDetail'));

const LazyProductDetail = () => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <ProductDetail />
        </Suspense>
    );
};

export default LazyProductDetail;
