import React, { useEffect, useState, createContext, useMemo } from 'react';
import { io } from 'socket.io-client';
import { SOCKET_URL } from '../configs/constants';

export const socketIo = io(SOCKET_URL, { withCredentials: true });
export const SocketContext = createContext({ socket: null, isSocketConnected: socketIo.connected });

const SocketContextProvider = ({ children }) => {
    const [isSocketConnected, setIsSocketConnected] = useState(socketIo.connected);

    // const localStorageValueAppTheme = localStorage.getItem(LOCALSTORAGE.appTheme.key);
    // const localStorageValueRememberMe = localStorage.getItem(LOCALSTORAGE.rememberMe.key);
    // const localStorageValueOrderListPanelElementPosition = localStorage.getItem(LOCALSTORAGE.orderListPanelElementPosition.key);
    // const localStorageValueProductDetailImageElementPosition = localStorage.getItem(LOCALSTORAGE.productDetailImageElementPosition.key);
    // const localStorageValueShippingAddressPanelElementPosition = localStorage.getItem(LOCALSTORAGE.shippingAddressPanelElementPosition.key);

    useEffect(() => {
        const onConnect = () => setIsSocketConnected(true);
        const onDisconnect = () => setIsSocketConnected(false);

        socketIo.on('connect', onConnect);
        socketIo.on('disconnect', onDisconnect);

        return () => {
            socketIo.off('connect', onConnect);
            socketIo.off('disconnect', onDisconnect);
        };
    }, []);

    // useEffect(() => {
    //     if (localStorageValueAppTheme === null) localStorage.setItem(LOCALSTORAGE.appTheme.key, LOCALSTORAGE.appTheme.initialValue);
    // }, [localStorageValueAppTheme]);

    // useEffect(() => {
    //     if (localStorageValueRememberMe === null) localStorage.setItem(LOCALSTORAGE.rememberMe.key, LOCALSTORAGE.rememberMe.initialValue);
    // }, [localStorageValueRememberMe]);

    // useEffect(() => {
    //     if (localStorageValueOrderListPanelElementPosition === null) localStorage.setItem(LOCALSTORAGE.orderListPanelElementPosition.key, LOCALSTORAGE.orderListPanelElementPosition.initialValue);
    // }, [localStorageValueOrderListPanelElementPosition]);

    // useEffect(() => {
    //     if (localStorageValueProductDetailImageElementPosition === null) localStorage.setItem(LOCALSTORAGE.productDetailImageElementPosition.key, LOCALSTORAGE.productDetailImageElementPosition.initialValue);
    // }, [localStorageValueProductDetailImageElementPosition]);

    // useEffect(() => {
    //     if (localStorageValueShippingAddressPanelElementPosition === null) localStorage.setItem(LOCALSTORAGE.shippingAddressPanelElementPosition.key, LOCALSTORAGE.shippingAddressPanelElementPosition.initialValue);
    // }, [localStorageValueShippingAddressPanelElementPosition]);

    const socket = useMemo(() => ({ socket: socketIo, isSocketConnected }), [isSocketConnected]);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};

export default SocketContextProvider;
