import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    authLoading: true,
    loginError: null,
    logoutError: null,
    authResult: null,
    registerError: null
};
export const userAuthSlice = createSlice({
    name: 'userAuth',
    initialState,
    reducers: {
        resetState: () => initialState,
        authLoadingStart: (state) => {
            state.authLoading = true;
        },
        authLoadingEnd: (state) => {
            state.authLoading = false;
        },
        loginFailure: (state, action) => {
            state.loginError = action.payload;
        },
        logoutFailure: (state, action) => {
            state.logoutError = action.payload;
        },
        loginSuccess: (state, action) => {
            state.authResult = action.payload;
            state.loginError = null;
        },
        setAuthResult: (state, action) => {
            state.authResult = action.payload;
        },
        reauthSuccess: (state, action) => {
            if (state.authResult === null) return;
            state.authResult = { ...state.authResult, accessToken: action.payload };
        },
        updateDocument: (state, action) => {
            if (state.authResult === null) return;
            state.authResult = { ...state.authResult, authData: { ...state.authResult?.authData, profilePicture: action.payload?.profilePicture } };
            state.updateError = null;
        },
        updateProfilePhoneVerified: (state, action) => {
            if (state.authResult === null) return;
            state.authResult = { ...state.authResult, authData: { ...state.authResult?.authData, phoneVerified: action.payload?.phoneVerified } };
            state.updateError = null;
        },
        updateProfilePhone: (state, action) => {
            if (state.authResult === null) return;
            state.authResult = { ...state.authResult, authData: { ...state.authResult?.authData, phone: action.payload?.phone, phoneVerified: false } };
            state.updateError = null;
        },
        updateProfileName: (state, action) => {
            if (state.authResult === null) return;
            state.authResult = { ...state.authResult, authData: { ...state.authResult?.authData, name: action.payload?.name } };
            state.updateError = null;
        },
        updateRole: (state, action) => {
            if (state.authResult === null) return;
            if (action.payload?.dissaproved) {
                state.authResult = { ...state.authResult, authData: { ...state.authResult?.authData, role: action.payload?.role, dissaproved: action.payload?.dissaproved, dissaprovedAt: action.payload?.dissaprovedAt } };
                state.updateError = null;
                return;
            }
            state.authResult = { ...state.authResult, authData: { ...state.authResult?.authData, role: action.payload?.role } };
            state.updateError = null;
        },
        updateAddress: (state) => {
            if (state.authResult === null) return;
            state.authResult = { ...state.authResult, authData: { ...state.authResult.authData, addressExists: true } };
            state.updateError = null;
        },
        deleteAddress: (state) => {
            if (state.authResult === null) return;
            state.authResult = { ...state.authResult, authData: { ...state.authResult.authData, addressExists: false } };
            state.updateError = null;
        },
        registerFailure: (state, action) => {
            state.registerError = action.payload.error;
        },
        registerFailureReset: (state) => {
            state.registerError = null;
        }
    }
});

export const userAuthAction = userAuthSlice.actions;
export const userAuthSelector = (state) => state.userAuth;
export default userAuthSlice.reducer;
