import { MIDTRANS } from '../configs/constants';

const seconds = 1000;
const minutes = seconds * 60;
const hours = minutes * 60;
// const days = hours * 24;
// const defaultPaymentExpiry = 1 * days;

// default expiry time for each payment method
const midtransDefaultPaymentExpiryTime = {
    "Bank Transfer": { "Permata": 24 * hours, "Mandiri Bill": 24 * hours, "BNI": 24 * hours, "BCA": 24 * hours, "BRI": 24 * hours, "others": 24 * hours },
    "QRIS": { "GoPay": 15 * minutes, "ShopeePay": 5 * minutes, "Others": 15 * minutes },
    "Over the Counter": { "Alfamart": 24 * hours, "Indomaret": 24 * hours },
    "Cardless Credit": { "Akulaku": 24 * hours },
    "Direct Debit": { "BCA KlikPay": 2 * hours, "OctoClicks": 2 * hours, "e-Pay BRI": 2 * hours, "CIMB Click": 2 * hours, "UOB EZ PAY": 14 * minutes }
};

const getPaymentMethodAndExpiryInMinute = (payment) => {
    let paymentMethod = '';
    let paymentExpiryInMinute = null;
    if (payment?.payment_type === MIDTRANS.payment.bank_transfer.type && payment?.permata_va_number) {
        paymentMethod = MIDTRANS.payment.bank_transfer.permata_va_number.name;
        paymentExpiryInMinute = midtransDefaultPaymentExpiryTime['Bank Transfer'].Permata / minutes;
    }
    if (payment?.payment_type === MIDTRANS.payment.bank_transfer.type && payment?.va_numbers?.[0]?.bank === MIDTRANS.payment.bank_transfer.va_number.bca.type) {
        paymentMethod = MIDTRANS.payment.bank_transfer.va_number.bca.name;
        paymentExpiryInMinute = midtransDefaultPaymentExpiryTime['Bank Transfer'].BCA / minutes;
    }
    if (payment?.payment_type === MIDTRANS.payment.bank_transfer.type && payment?.va_numbers?.[0]?.bank === MIDTRANS.payment.bank_transfer.va_number.bni.type) {
        paymentMethod = MIDTRANS.payment.bank_transfer.va_number.bni.name;
        paymentExpiryInMinute = midtransDefaultPaymentExpiryTime['Bank Transfer'].BNI / minutes;
    }
    if (payment?.payment_type === MIDTRANS.payment.bank_transfer.type && payment?.va_numbers?.[0]?.bank === MIDTRANS.payment.bank_transfer.va_number.bri.type) {
        paymentMethod = MIDTRANS.payment.bank_transfer.va_number.bri.name;
        paymentExpiryInMinute = midtransDefaultPaymentExpiryTime['Bank Transfer'].BRI / minutes;
    }
    if (payment?.payment_type === MIDTRANS.payment.gopay.type) {
        paymentMethod = MIDTRANS.payment.gopay.name;
        paymentExpiryInMinute = midtransDefaultPaymentExpiryTime.QRIS.GoPay;
    }
    if (payment?.payment_type === MIDTRANS.payment.shopeepay.type) {
        paymentMethod = MIDTRANS.payment.shopeepay.name;
        paymentExpiryInMinute = midtransDefaultPaymentExpiryTime.QRIS.ShopeePay;
    }
    if (payment?.payment_type === MIDTRANS.payment.qris.type) {
        paymentMethod = MIDTRANS.payment.qris.name;
        paymentExpiryInMinute = midtransDefaultPaymentExpiryTime.QRIS.Others;
    }
    if (payment?.payment_type === MIDTRANS.payment.echannel.type) {
        paymentMethod = MIDTRANS.payment.echannel.name;
        paymentExpiryInMinute = midtransDefaultPaymentExpiryTime['Bank Transfer']['Mandiri Bill'] / minutes;
    }
    if (payment?.payment_type === MIDTRANS.payment.bca_klikpay.type) {
        paymentMethod = MIDTRANS.payment.bca_klikpay.name;
        paymentExpiryInMinute = midtransDefaultPaymentExpiryTime['Direct Debit']['BCA KlikPay'] / minutes;
    }
    if (payment?.payment_type === MIDTRANS.payment.bca_klikbca.type) paymentMethod = MIDTRANS.payment.bca_klikbca.name; // todo
    if (payment?.payment_type === MIDTRANS.payment.cimb_clicks.type) {
        paymentMethod = MIDTRANS.payment.cimb_clicks.name;
        paymentExpiryInMinute = midtransDefaultPaymentExpiryTime['Direct Debit']['CIMB Click'] / minutes;
    }
    if (payment?.payment_type === MIDTRANS.payment.danamon_online.type) paymentMethod = MIDTRANS.payment.danamon_online.name; // todo
    if (payment?.payment_type === MIDTRANS.payment.credit_card.type) paymentMethod = MIDTRANS.payment.credit_card.name + ' - ' + payment?.bank; // todo
    if (payment?.payment_type === MIDTRANS.payment.akulaku.type) {
        paymentMethod = MIDTRANS.payment.akulaku.name;
        paymentExpiryInMinute = midtransDefaultPaymentExpiryTime['Cardless Credit'].Akulaku / minutes;
    }
    if (payment?.payment_type === MIDTRANS.payment.bri_epay.type) paymentMethod = MIDTRANS.payment.bri_epay.name; // todo
    if (payment?.payment_type === MIDTRANS.payment.cstore.type && payment?.store === MIDTRANS.payment.cstore.store.indomaret.type) {
        paymentMethod = MIDTRANS.payment.cstore.store.indomaret.name;
        paymentExpiryInMinute = midtransDefaultPaymentExpiryTime['Over the Counter'].Indomaret / minutes;
    }
    if (payment?.payment_type === MIDTRANS.payment.cstore.type && payment?.store === MIDTRANS.payment.cstore.store.alfamart.type) {
        paymentMethod = MIDTRANS.payment.cstore.store.alfamart.name;
        paymentExpiryInMinute = midtransDefaultPaymentExpiryTime['Over the Counter'].Alfamart / minutes;
    }
    return { paymentMethod, paymentExpiryInMinute };
};

export default getPaymentMethodAndExpiryInMinute;
