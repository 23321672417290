import axios from 'axios';
import { MAIN_API_URL } from '../configs/constants';

export default axios.create({
    baseURL: MAIN_API_URL
});

export const axiosBasic = axios.create({
    baseURL: MAIN_API_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});
