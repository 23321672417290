import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { userAuthSelector } from '../redux/slices/userAuthSlice';
import LoadingSpinner from '../components/LoadingSpinner';
import { ROLES_LIST } from '../configs/constants';

const ChatBuyer = lazy(() => import('../components/ChatBuyer'));
const ChatSeller = lazy(() => import('../components/ChatSeller'));

const LazyChat = () => {
    const { authResult } = useSelector(userAuthSelector);

    if (authResult?.authData?.role === ROLES_LIST.Buyer) {
        return (
            <Suspense fallback={<LoadingSpinner />}>
                <ChatBuyer />
            </Suspense>
        );
    }

    if (authResult?.authData?.role === ROLES_LIST.Seller) {
        return (
            <Suspense fallback={<LoadingSpinner />}>
                <ChatSeller />
            </Suspense>
        );
    }

    return null;
};

export default LazyChat;
