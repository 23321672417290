import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    getAllLoading: true,
    getAllResult: null,
    getAllMessage: null,
    getAllHasMore: false,
    getAllError: null,
    getAllIterator: new Date().toISOString(),

    searchLoading: true,
    searchResult: null,
    searchMessage: null,
    searchHasMore: false,
    searchError: null,
    searchIterator: new Date().toISOString(),

    categoryLoading: true,
    categoryResult: null,
    categoryMessage: null,
    categoryHasMore: false,
    categoryError: null,
    categoryIterator: new Date().toISOString(),

    getOneLoading: true,
    getOneResult: null,
    getOneError: null,

    upsertLoading: false,
    upsertSuccess: null,
    upsertError: null,
    deleteError: null
};

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        resetState: () => initialState,
        getAllStart: (state) => {
            state.getAllLoading = true;
        },
        getAllEnd: (state) => {
            state.getAllLoading = false;
        },
        getAllSuccess: (state, action) => {
            const newGetAllResultState = state.getAllResult === null ? [...action.payload.result] : [...new Map([...state.getAllResult, ...action.payload.result].map((v) => [v._id, v])).values()];
            state.getAllResult = newGetAllResultState;
            state.getAllHasMore = action.payload.hasMore;
            state.getAllMessage = newGetAllResultState ? null : 'Belum ada distributor yang menampilkan produk.';
            state.getAllError = null;
        },
        getAllFailure: (state, action) => {
            state.getAllError = action.payload;
        },
        getAllNext: (state) => {
            if (state.getAllResult === null) return;
            state.getAllIterator = new Date(state.getAllResult?.[state.getAllResult?.length - 1]?.updatedAt).toISOString();
        },
        searchStart: (state) => {
            state.searchLoading = true;
        },
        searchEnd: (state) => {
            state.searchLoading = false;
        },
        searchSuccess: (state, action) => {
            const newSearchResult = state.searchResult === null ? [...action.payload.result] : [...new Map([...state.searchResult, ...action.payload.result].map((v) => [v._id, v])).values()];
            state.searchResult = newSearchResult;
            state.searchHasMore = action.payload.hasMore;
            state.searchMessage = newSearchResult ? `Menampilkan (${newSearchResult.length}/${action.payload.total}) hasil pencarian produk "${action.payload.searchQuery}".` : `Hasil pencarian produk "${action.payload.searchQuery}" tidak ditemukan.`;
            state.searchError = null;
        },
        searchFailure: (state, action) => {
            state.searchError = action.payload;
        },
        searchReset: (state) => {
            state.searchLoading = true;
            state.searchResult = null;
            state.searchMessage = null;
            state.searchHasMore = false;
            state.searchError = null;
            // state.searchIterator = new Date().toISOString();
        },
        searchNext: (state) => {
            if (state.searchResult === null) return;
            state.searchIterator = new Date(state.searchResult?.[state.searchResult?.length - 1]?.updatedAt).toISOString();
        },
        categoryStart: (state) => {
            state.categoryLoading = true;
        },
        categoryEnd: (state) => {
            state.categoryLoading = false;
        },
        categorySuccess: (state, action) => {
            const newCategoryResult = state.categoryResult === null ? [...action.payload.result] : [...new Map([...state.categoryResult, ...action.payload.result].map((v) => [v._id, v])).values()];
            state.categoryResult = newCategoryResult;
            state.categoryHasMore = action.payload.hasMore;
            state.categoryMessage = newCategoryResult ? `Menampilkan (${newCategoryResult.length}/${action.payload.total}) produk dengan ketegori "${action.payload.categoryQuery}".` : `Produk dengan kategori "${action.payload.categoryQuery}" tidak ditemukan.`;
            state.categoryError = null;
        },
        categoryFailure: (state, action) => {
            state.categoryError = action.payload;
        },
        categoryReset: (state) => {
            state.categoryLoading = true;
            state.categoryResult = null;
            state.categoryMessage = null;
            state.categoryHasMore = false;
            state.categoryError = null;
            // state.categoryIterator = new Date().toISOString();
        },
        categoryNext: (state) => {
            if (state.categoryResult === null) return;
            state.categoryIterator = new Date(state.categoryResult?.[state.categoryResult?.length - 1]?.updatedAt).toISOString();
        },
        getOneStart: (state) => {
            state.getOneLoading = true;
        },
        getOneEnd: (state) => {
            state.getOneLoading = false;
        },
        getOneSuccess: (state, action) => {
            state.getOneResult = action.payload.result;
            state.getOneError = null;
        },
        getOneFailure: (state, action) => {
            state.getOneError = action.payload;
        },
        postSuccess: (state, action) => {
            state.getAllResult = state.getAllResult ? [action.payload.result, ...state.getAllResult] : [action.payload.result];
            state.searchResult = action.payload.result.name.includes(state.searchQuery)
                ? state.searchResult
                    ? [action.payload.result, ...state.searchResult]
                    : [action.payload.result]
                : state.searchResult;
            state.upsertSuccess = action.payload.message;
            state.upsertError = null;
        },
        patchLoadingStart: (state) => {
            state.upsertLoading = true;
        },
        patchLoadingEnd: (state) => {
            state.upsertLoading = false;
        },
        patchSuccess: (state, action) => {
            if (action.payload?.result === undefined || action.payload?.result === null) return;
            if (state.getAllResult !== null) state.getAllResult = state.getAllResult?.map?.((product) => (product?._id === action.payload?.result?._id ? action.payload?.result : product));
            if (state.searchResult !== null) state.searchResult = state.searchResult?.map?.((product) => (product?._id === action.payload?.result?._id ? action.payload?.result : product));
            if (state.categoryResult !== null) state.categoryResult = state.categoryResult?.map?.((product) => (product?._id === action.payload?.result?._id ? action.payload?.result : product));
            state.upsertSuccess = action.payload.message;
            state.upsertError = null;
        },
        upsertFailure: (state, action) => {
            state.upsertError = action.payload;
        },
        upsertReset: (state) => {
            state.upsertSuccess = null;
            state.upsertError = null;
        },
        deleteSuccess: (state, action) => {
            const getAllResultFiltered = state.getAllResult?.filter?.((product) => product?._id !== action.payload.productId);
            state.getAllResult = getAllResultFiltered?.length > 0 ? getAllResultFiltered : null;
            const searchResultFiltered = state.searchResult?.filter?.((product) => product?._id !== action.payload.productId);
            state.searchResult = searchResultFiltered?.length > 0 ? searchResultFiltered : null;
            const categoryResultFiltered = state.categoryResult?.filter?.((product) => product?._id !== action.payload.productId);
            state.categoryResult = categoryResultFiltered?.length > 0 ? categoryResultFiltered : null;
            state.upsertSuccess = action.payload.message;
            state.deleteError = null;
        },
        deleteFailure: (state, action) => {
            state.deleteError = action.payload;
        },
        deleteFailureReset: (state) => {
            state.deleteError = null;
        }
    }
});

export const productAction = productSlice.actions;
export const productSelector = (state) => state.product;
export const productFormSelector = (state, currentId) => {
    if (currentId === null) return null;
    if (state.product.getAllResult && state.product.searchResult && state.product.categoryResult) return [...state.product.getAllResult, ...state.product.searchResult, ...state.product.categoryResult].find((product) => product?._id === currentId);
    if (state.product.getAllResult && state.product.searchResult) return [...state.product.getAllResult, ...state.product.searchResult].find((product) => product?._id === currentId);
    if (state.product.getAllResult) return [...state.product.getAllResult].find((product) => product?._id === currentId);
    return null;
};
export default productSlice.reducer;
