import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAnyUnsavedChanges: false,
    openUnsavedChangesDialog: false,
};

export const unsavedChangesSlice = createSlice({
    name: 'unsavedChanges',
    initialState,
    reducers: {
        resetState: () => initialState,
        setIsAnyUnsavedChanges: (state, action) => {
            state.isAnyUnsavedChanges = action.payload;
        },
        setOpenUnsavedChangesDialog: (state, action) => {
            state.openUnsavedChangesDialog = action.payload;
        },
    }
});

export const unsavedChangesAction = unsavedChangesSlice.actions;
export const unsavedChangesSelector = (state) => state.unsavedChanges;
export default unsavedChangesSlice.reducer;
