import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROLES_LIST, ROUTE_LIST } from './configs/constants';
import PersistLogin from './components/PersistLogin';
import Layout from './components/Layout';
import RequireNoAuth from './components/RequireNoAuth';
import RequireAuthorizedRole from './components/RequireAuthorizedRole';
import LazyHome from './pages/LazyHome';
import LazyProductDetail from './pages/LazyProductDetail';
import LazyUserRegistration from './pages/LazyUserRegistration';
import LazyUserLogin from './pages/LazyUserLogin';
import LazyUserProfile from './pages/LazyUserProfile';
import LazyUserAddress from './pages/LazyUserAddress';
import LazyUserVerificationEmailPhone from './pages/LazyUserVerificationEmailPhone';
import LazyChat from './pages/LazyChat';
import LazyCart from './pages/LazyCart';
import LazyShipment from './pages/LazyShipment';
import LazyOrderListBuyerWaitingPayment from './pages/LazyOrderListBuyerWaitingPayment';
import LazyPaymentStatus from './pages/LazyPaymentStatus';
import LazyOrderList from './pages/LazyOrderList';
import LazyInvoice from './pages/LazyInvoice';
import LazySellerProfile from './pages/LazySellerProfile';
import LazyOrderLabelPrint from './pages/LazyOrderLabelPrint';

const App = () => {
    return (
        <Routes>
            <Route path="/">
                <Route element={<PersistLogin />}>
                    {/* <Route element={<RequireAuthorizedRole allowedRoles={[ROLES_LIST.Buyer]} />}>
                        <Route path={ROUTE_LIST.invoice.pathname} element={<LazyInvoice />} />
                    </Route> */}
                    <Route element={<RequireNoAuth />}>
                        <Route path={ROUTE_LIST.register.pathname} element={<LazyUserRegistration />} />
                        <Route path={ROUTE_LIST.login.pathname} element={<LazyUserLogin />} />
                    </Route>
                    <Route element={<RequireAuthorizedRole allowedRoles={[ROLES_LIST.Buyer, ROLES_LIST.Seller]} />}>
                        <Route path={ROUTE_LIST.invoice.pathname} element={<LazyInvoice />} />
                    </Route>
                    <Route element={<RequireAuthorizedRole allowedRoles={[ROLES_LIST.Seller]} />}>
                        <Route path={ROUTE_LIST.orderLabelPrint.pathname} element={<LazyOrderLabelPrint />} />
                    </Route>
                    <Route element={<Layout />}>
                        <Route index element={<LazyHome />} />
                        <Route path={':' + ROUTE_LIST.product.params.productId} element={<LazyProductDetail />} />
                        <Route element={<RequireAuthorizedRole allowedRoles={[ROLES_LIST.Buyer, ROLES_LIST.Seller]} />}>
                            <Route path={ROUTE_LIST.profile.pathname} element={<LazyUserProfile />} />
                            <Route path={ROUTE_LIST.address.pathname} element={<LazyUserAddress />} />
                            <Route path={ROUTE_LIST.chat.pathname} element={<LazyChat />} />
                            <Route path={ROUTE_LIST.orderList.pathname} element={<LazyOrderList />} />
                            {/* <Route path={ROUTE_LIST.invoice.pathname} element={<LazyInvoice />} /> */}
                        </Route>
                        <Route element={<RequireAuthorizedRole allowedRoles={[ROLES_LIST.Buyer]} />}>
                            <Route path={ROUTE_LIST.emailAndPhoneNumberVerification.pathname} element={<LazyUserVerificationEmailPhone />} />
                            <Route path={ROUTE_LIST.payment.pathname}>
                                <Route index element={<LazyOrderListBuyerWaitingPayment />} />
                                <Route path={':' + ROUTE_LIST.payment.params.transactionId} element={<LazyPaymentStatus />} />
                            </Route>
                            <Route path={ROUTE_LIST.cart.pathname}>
                                <Route index element={<LazyCart />} />
                                <Route path={ROUTE_LIST.cart.shipment.pathname} element={<LazyShipment />} />
                            </Route>
                        </Route>
                        <Route element={<RequireAuthorizedRole allowedRoles={[ROLES_LIST.Seller]} />}>
                            <Route path={ROUTE_LIST.profileSeller.pathname} element={<LazySellerProfile />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
};

export default App;
