import React, { lazy, Suspense } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';

const UserProfile = lazy(() => import('../components/UserProfile'));

const LazyUserProfile = () => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <UserProfile />
        </Suspense>
    );
};

export default LazyUserProfile;
