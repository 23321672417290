import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { ROUTE_LIST } from '../configs/constants';
import Unauthorized from '../pages/Unauthorized';
import { userAuthSelector } from '../redux/slices/userAuthSlice';
import LoadingSpinner from './LoadingSpinner';

const RequireAuthorizedRole = ({ allowedRoles }) => {
    const { authResult, authLoading } = useSelector(userAuthSelector);
    const location = useLocation();

    return authLoading
        ? <LoadingSpinner />
        : allowedRoles?.includes?.(authResult?.authData?.role)
            ? <Outlet />
            : authResult?.authData
                ? <Unauthorized />
                : <Navigate to={'/' + ROUTE_LIST.login.pathname} state={{ from: location }} replace />;
};

export default RequireAuthorizedRole;
