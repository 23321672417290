import React, { useEffect, useState } from 'react';
import { Fab, useTheme } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import useColorModeContext from '../hooks/useColorModeContext';
import useWindowDimensions from '../hooks/useWindowDimensions';

const SuperAdminPanel = () => {
    const theme = useTheme();
    const { toggleColorMode } = useColorModeContext();
    const [fabPosition, setFabPosition] = useState({ x: '0px', y: '0px' });
    const { width, height } = useWindowDimensions();

    useEffect(() => {
        setFabPosition({ x: '0px', y: `${height - 8 - 56 - 8}px` });
        return () => setFabPosition({ x: '0px', y: `${height - 8 - 56 - 8}px` });
    }, [width, height]);

    const handleFabDrag = (evt) => {
        // console.log(evt);
        setFabPosition({ x: `${evt?.clientX || 0}px`, y: `${evt?.clientY || 0}px` });
    };

    return (
        <Fab
            onClick={toggleColorMode}
            onDragEnd={handleFabDrag}
            draggable
            sx={{ margin: '8px', position: 'fixed', zIndex: 1101, top: fabPosition.y, left: fabPosition.x }}
            aria-label="product-list-add-new-fab"
            color="primary"
        >
            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </Fab>
    );
};

export default SuperAdminPanel;
