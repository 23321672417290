import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { userAuthSelector } from '../redux/slices/userAuthSlice';
import LoadingSpinner from '../components/LoadingSpinner';
import { ROLES_LIST } from '../configs/constants';

const OrderListBuyer = lazy(() => import('../components/OrderListBuyer'));
const OrderListSeller = lazy(() => import('../components/OrderListSeller'));

const LazyOrderList = () => {
    const { authResult } = useSelector(userAuthSelector);

    if (authResult?.authData?.role === ROLES_LIST.Buyer) {
        return (
            <Suspense fallback={<LoadingSpinner />}>
                <OrderListBuyer />
            </Suspense>
        );
    }

    if (authResult?.authData?.role === ROLES_LIST.Seller) {
        return (
            <Suspense fallback={<LoadingSpinner />}>
                <OrderListSeller />
            </Suspense>
        );
    }

    return null;
};

export default LazyOrderList;
