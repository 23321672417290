import { createSlice } from '@reduxjs/toolkit';
import getPaymentMethodAndExpiryInMinute from '../../utils/getPaymentMethodAndExpiryInMinute';

const initialState = {
    getPaymentStatusLoading: true,
    getPaymentStatusResult: null,
    getPaymentStatusError: null,

    selectedOrder: null,

    getCurrentLogsLoading: true,
    getCurrentLogsResult: null,
    getCurrentLogsError: null,

    getOrderListBuyerWaitingPaymentLoading: true,
    getOrderListBuyerWaitingPaymentResult: null,
    getOrderListBuyerWaitingPaymentMessage: null,
    getOrderListBuyerWaitingPaymentError: null,

    getOrderListBuyerWaitingPaymentCancelOrderSuccessMessage: null,
    getOrderListBuyerWaitingPaymentCancelOrderErrorMessage: null,

    getOrderListSellerLoadingSemua: false,
    getOrderListSellerMessageSemua: null,
    getOrderListSellerErrorSemua: null,
    getOrderListSellerIteratorSemua: null,
    getOrderListSellerResultSemua: null,
    getOrderListSellerHasMoreSemua: false,

    getOrderListSellerLoadingMenungguKonfirmasiOrPesananBaru: false,
    getOrderListSellerMessageMenungguKonfirmasiOrPesananBaru: null,
    getOrderListSellerErrorMenungguKonfirmasiOrPesananBaru: null,
    getOrderListSellerIteratorMenungguKonfirmasiOrPesananBaru: null,
    getOrderListSellerResultMenungguKonfirmasiOrPesananBaru: null,
    getOrderListSellerHasMoreMenungguKonfirmasiOrPesananBaru: false,

    getOrderListSellerLoadingDiprosesOrSiapDikirim: false,
    getOrderListSellerMessageDiprosesOrSiapDikirim: null,
    getOrderListSellerErrorDiprosesOrSiapDikirim: null,
    getOrderListSellerIteratorDiprosesOrSiapDikirim: null,
    getOrderListSellerResultDiprosesOrSiapDikirim: null,
    getOrderListSellerHasMoreDiprosesOrSiapDikirim: false,

    getOrderListSellerLoadingDikirimOrDalamPengiriman: false,
    getOrderListSellerMessageDikirimOrDalamPengiriman: null,
    getOrderListSellerErrorDikirimOrDalamPengiriman: null,
    getOrderListSellerIteratorDikirimOrDalamPengiriman: null,
    getOrderListSellerResultDikirimOrDalamPengiriman: null,
    getOrderListSellerHasMoreDikirimOrDalamPengiriman: false,

    getOrderListSellerLoadingBerhasilOrPesananSelesai: false,
    getOrderListSellerMessageBerhasilOrPesananSelesai: null,
    getOrderListSellerErrorBerhasilOrPesananSelesai: null,
    getOrderListSellerIteratorBerhasilOrPesananSelesai: null,
    getOrderListSellerResultBerhasilOrPesananSelesai: null,
    getOrderListSellerHasMoreBerhasilOrPesananSelesai: false,

    getOrderListSellerLoadingTidakBerhasilOrDibatalkanPenjual: false,
    getOrderListSellerMessageTidakBerhasilOrDibatalkanPenjual: null,
    getOrderListSellerErrorTidakBerhasilOrDibatalkanPenjual: null,
    getOrderListSellerIteratorTidakBerhasilOrDibatalkanPenjual: null,
    getOrderListSellerResultTidakBerhasilOrDibatalkanPenjual: null,
    getOrderListSellerHasMoreTidakBerhasilOrDibatalkanPenjual: false,

    getOrderListBuyerLoadingBerlangsung: false,
    getOrderListBuyerMessageBerlangsung: null,
    getOrderListBuyerErrorBerlangsung: null,
    getOrderListBuyerIteratorBerlangsung: null,
    getOrderListBuyerResultBerlangsung: null,
    getOrderListBuyerHasMoreBerlangsung: false,

    getOrderListBuyerLoadingMenungguKonfirmasiOrPesananBaru: false,
    getOrderListBuyerMessageMenungguKonfirmasiOrPesananBaru: null,
    getOrderListBuyerErrorMenungguKonfirmasiOrPesananBaru: null,
    getOrderListBuyerIteratorMenungguKonfirmasiOrPesananBaru: null,
    getOrderListBuyerResultMenungguKonfirmasiOrPesananBaru: null,
    getOrderListBuyerHasMoreMenungguKonfirmasiOrPesananBaru: false,

    getOrderListBuyerLoadingDiprosesOrSiapDikirim: false,
    getOrderListBuyerMessageDiprosesOrSiapDikirim: null,
    getOrderListBuyerErrorDiprosesOrSiapDikirim: null,
    getOrderListBuyerIteratorDiprosesOrSiapDikirim: null,
    getOrderListBuyerResultDiprosesOrSiapDikirim: null,
    getOrderListBuyerHasMoreDiprosesOrSiapDikirim: false,

    getOrderListBuyerLoadingDikirimOrDalamPengiriman: false,
    getOrderListBuyerMessageDikirimOrDalamPengiriman: null,
    getOrderListBuyerErrorDikirimOrDalamPengiriman: null,
    getOrderListBuyerIteratorDikirimOrDalamPengiriman: null,
    getOrderListBuyerResultDikirimOrDalamPengiriman: null,
    getOrderListBuyerHasMoreDikirimOrDalamPengiriman: false,

    getOrderListBuyerLoadingSampaiTujuan: false,
    getOrderListBuyerMessageSampaiTujuan: null,
    getOrderListBuyerErrorSampaiTujuan: null,
    getOrderListBuyerIteratorSampaiTujuan: null,
    getOrderListBuyerResultSampaiTujuan: null,
    getOrderListBuyerHasMoreSampaiTujuan: false,

    getOrderListBuyerLoadingBerhasilOrPesananSelesai: false,
    getOrderListBuyerMessageBerhasilOrPesananSelesai: null,
    getOrderListBuyerErrorBerhasilOrPesananSelesai: null,
    getOrderListBuyerIteratorBerhasilOrPesananSelesai: null,
    getOrderListBuyerResultBerhasilOrPesananSelesai: null,
    getOrderListBuyerHasMoreBerhasilOrPesananSelesai: false,

    getOrderListBuyerLoadingTidakBerhasilOrDibatalkanPenjual: false,
    getOrderListBuyerMessageTidakBerhasilOrDibatalkanPenjual: null,
    getOrderListBuyerErrorTidakBerhasilOrDibatalkanPenjual: null,
    getOrderListBuyerIteratorTidakBerhasilOrDibatalkanPenjual: null,
    getOrderListBuyerResultTidakBerhasilOrDibatalkanPenjual: null,
    getOrderListBuyerHasMoreTidakBerhasilOrDibatalkanPenjual: false,

    getOrderListBuyerLoadingSemua: false,
    getOrderListBuyerMessageSemua: null,
    getOrderListBuyerErrorSemua: null,
    getOrderListBuyerIteratorSemua: null,
    getOrderListBuyerResultSemua: null,
    getOrderListBuyerHasMoreSemua: false,

    getOrderByInvoiceCodeLoading: true,
    getOrderByInvoiceCodeResult: null,
    getOrderByInvoiceCodeError: null,

    requestPickupPackageSicepatLoading: false,
    requestPickupPackageSicepatResult: null,
    requestPickupPackageSicepatError: null,
};

const updateResult = (currentResult, newResult) => {
    if (currentResult !== null && newResult !== null) return [...new Map([...currentResult, ...newResult].map((v) => [v._id, v])).values()];
    if (currentResult === null && newResult !== null) return [...newResult];
    if (currentResult !== null && newResult === null) return currentResult;
    return null;
};

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        resetState: () => initialState,
        getPaymentStatusStart: (state) => {
            state.getPaymentStatusLoading = true;
        },
        getPaymentStatusEnd: (state) => {
            state.getPaymentStatusLoading = false;
        },
        getPaymentStatusSuccess: (state, action) => {
            const { paymentExpiryInMinute, paymentMethod } = getPaymentMethodAndExpiryInMinute(action.payload?.payment);
            state.getPaymentStatusResult = { ...action.payload, paymentExpiryInMinute, paymentMethod };
            state.getPaymentStatusError = null;
        },
        getPaymentStatusFailure: (state, action) => {
            state.getPaymentStatusError = action.payload;
        },
        setSelectedOrder: (state, action) => {
            state.selectedOrder = action.payload;
        },
        getCurrentLogsStart: (state) => {
            state.getCurrentLogsLoading = true;
        },
        getCurrentLogsEnd: (state) => {
            state.getCurrentLogsLoading = false;
        },
        getCurrentLogsSuccess: (state, action) => {
            state.selectedOrder = action.payload;
            state.getCurrentLogsError = null;
        },
        getCurrentLogsFailure: (state, action) => {
            state.getCurrentLogsError = action.payload;
        },
        requestPickupPackageSicepatStart: (state) => {
            state.requestPickupPackageSicepatLoading = true;
        },
        requestPickupPackageSicepatEnd: (state) => {
            state.requestPickupPackageSicepatLoading = false;
        },
        requestPickupPackageSicepatSuccess: (state, action) => {
            state.requestPickupPackageSicepatResult = action.payload;
            state.requestPickupPackageSicepatError = null;
        },
        requestPickupPackageSicepatFailure: (state, action) => {
            state.requestPickupPackageSicepatError = action.payload;
        },
        getOrderListBuyerWaitingPaymentStart: (state) => {
            state.getOrderListBuyerWaitingPaymentLoading = true;
        },
        getOrderListBuyerWaitingPaymentEnd: (state) => {
            state.getOrderListBuyerWaitingPaymentLoading = false;
        },
        getOrderListBuyerWaitingPaymentSuccess: (state, action) => {
            state.getOrderListBuyerWaitingPaymentResult = action.payload;
            state.getOrderListBuyerWaitingPaymentMessage = action.payload === null ? 'Tidak ada pesanan yang belum dibayar' : null;
            state.getOrderListBuyerWaitingPaymentError = null;
        },
        getOrderListBuyerWaitingPaymentFailure: (state, action) => {
            state.getOrderListBuyerWaitingPaymentError = action.payload;
        },
        getOrderListBuyerWaitingPaymentCancelOrderSuccess: (state, action) => {
            if (state.getOrderListBuyerWaitingPaymentResult === null || action.payload?.orderId === undefined || action.payload?.orderId === null) return;
            const getOrderListBuyerWaitingPaymentResultNew = state.getOrderListBuyerWaitingPaymentResult?.filter?.((orderDoc) => orderDoc?._id !== action.payload?.orderId);
            state.getOrderListBuyerWaitingPaymentResult = getOrderListBuyerWaitingPaymentResultNew?.length > 0 ? getOrderListBuyerWaitingPaymentResultNew : null;
            state.getOrderListBuyerWaitingPaymentCancelOrderSuccessMessage = action.payload?.message;
            state.getOrderListBuyerWaitingPaymentCancelOrderErrorMessage = null;
        },
        getOrderListBuyerWaitingPaymentCancelOrderFailure: (state, action) => {
            state.getOrderListBuyerWaitingPaymentCancelOrderErrorMessage = action.payload;
        },
        getOrderListBuyerWaitingPaymentCancelOrderResetMessage: (state) => {
            state.getOrderListBuyerWaitingPaymentCancelOrderSuccessMessage = null;
            state.getOrderListBuyerWaitingPaymentCancelOrderErrorMessage = null;
        },

        getOrderListBuyerStartSemua: (state) => {
            state.getOrderListBuyerLoadingSemua = true;
        },
        getOrderListBuyerFinishSemua: (state) => {
            state.getOrderListBuyerLoadingSemua = false;
        },
        getOrderListBuyerSuccessSemua: (state, action) => {
            const getOrderListBuyerResultSemuaNew = updateResult(state.getOrderListBuyerResultSemua, action.payload?.result);
            state.getOrderListBuyerResultSemua = getOrderListBuyerResultSemuaNew;
            state.getOrderListBuyerHasMoreSemua = action.payload?.hasMore;
            state.getOrderListBuyerMessageSemua = getOrderListBuyerResultSemuaNew === null ? 'Belum ada transaksi' : null;
            state.getOrderListBuyerErrorSemua = null;
        },
        getOrderListBuyerFailureSemua: (state, action) => {
            state.getOrderListBuyerErrorSemua = action.payload;
        },
        getOrderListBuyerFailureResetSemua: (state) => {
            state.getOrderListBuyerErrorSemua = null;
        },
        getOrderListBuyerNextSemua: (state) => {
            if (state.getOrderListBuyerResultSemua === null) return;
            state.getOrderListBuyerIteratorSemua = new Date(state.getOrderListBuyerResultSemua?.[state.getOrderListBuyerResultSemua.length - 1]?.createdAt).toISOString();
        },
        getOrderListBuyerResetSemua: (state) => {
            state.getOrderListBuyerIteratorSemua = null;
            state.getOrderListBuyerResultSemua = null;
            state.getOrderListBuyerHasMoreSemua = false;
            state.getOrderListBuyerMessageSemua = null;
            state.getOrderListBuyerErrorSemua = null;
        },

        getOrderListBuyerStartBerlangsung: (state) => {
            state.getOrderListBuyerLoadingBerlangsung = true;
        },
        getOrderListBuyerFinishBerlangsung: (state) => {
            state.getOrderListBuyerLoadingBerlangsung = false;
        },
        getOrderListBuyerSuccessBerlangsung: (state, action) => {
            const getOrderListBuyerResultBerlangsungNew = updateResult(state.getOrderListBuyerResultBerlangsung, action.payload?.result);
            state.getOrderListBuyerResultBerlangsung = getOrderListBuyerResultBerlangsungNew;
            state.getOrderListBuyerHasMoreBerlangsung = action.payload?.hasMore;
            state.getOrderListBuyerMessageBerlangsung = getOrderListBuyerResultBerlangsungNew === null ? 'Belum ada transaksi' : null;
            state.getOrderListBuyerErrorBerlangsung = null;
        },
        getOrderListBuyerFailureBerlangsung: (state, action) => {
            state.getOrderListBuyerErrorBerlangsung = action.payload;
        },
        getOrderListBuyerFailureResetBerlangsung: (state) => {
            state.getOrderListBuyerErrorBerlangsung = null;
        },
        getOrderListBuyerNextBerlangsung: (state) => {
            if (state.getOrderListBuyerResultBerlangsung === null) return;
            state.getOrderListBuyerIteratorBerlangsung = new Date(state.getOrderListBuyerResultBerlangsung?.[state.getOrderListBuyerResultBerlangsung.length - 1]?.createdAt).toISOString();
        },
        getOrderListBuyerResetBerlangsung: (state) => {
            state.getOrderListBuyerIteratorBerlangsung = null;
            state.getOrderListBuyerResultBerlangsung = null;
            state.getOrderListBuyerHasMoreBerlangsung = false;
            state.getOrderListBuyerMessageBerlangsung = null;
            state.getOrderListBuyerErrorBerlangsung = null;
        },

        getOrderListBuyerStartMenungguKonfirmasiOrPesananBaru: (state) => {
            state.getOrderListBuyerLoadingMenungguKonfirmasiOrPesananBaru = true;
        },
        getOrderListBuyerFinishMenungguKonfirmasiOrPesananBaru: (state) => {
            state.getOrderListBuyerLoadingMenungguKonfirmasiOrPesananBaru = false;
        },
        getOrderListBuyerSuccessMenungguKonfirmasiOrPesananBaru: (state, action) => {
            const getOrderListBuyerResultMenungguKonfirmasiNew = updateResult(state.getOrderListBuyerResultMenungguKonfirmasiOrPesananBaru, action.payload?.result);
            state.getOrderListBuyerResultMenungguKonfirmasiOrPesananBaru = getOrderListBuyerResultMenungguKonfirmasiNew;
            state.getOrderListBuyerHasMoreMenungguKonfirmasiOrPesananBaru = action.payload?.hasMore;
            state.getOrderListBuyerMessageMenungguKonfirmasiOrPesananBaru = getOrderListBuyerResultMenungguKonfirmasiNew === null ? 'Belum ada transaksi' : null;
            state.getOrderListBuyerErrorMenungguKonfirmasiOrPesananBaru = null;
        },
        getOrderListBuyerFailureMenungguKonfirmasiOrPesananBaru: (state, action) => {
            state.getOrderListBuyerErrorMenungguKonfirmasiOrPesananBaru = action.payload;
        },
        getOrderListBuyerFailureResetMenungguKonfirmasiOrPesananBaru: (state) => {
            state.getOrderListBuyerErrorMenungguKonfirmasiOrPesananBaru = null;
        },
        getOrderListBuyerNextMenungguKonfirmasiOrPesananBaru: (state) => {
            if (state.getOrderListBuyerResultMenungguKonfirmasiOrPesananBaru === null) return;
            state.getOrderListBuyerIteratorMenungguKonfirmasiOrPesananBaru = new Date(state.getOrderListBuyerResultMenungguKonfirmasiOrPesananBaru?.[state.getOrderListBuyerResultMenungguKonfirmasiOrPesananBaru.length - 1]?.createdAt).toISOString();
        },
        getOrderListBuyerResetMenungguKonfirmasiOrPesananBaru: (state) => {
            state.getOrderListBuyerIteratorMenungguKonfirmasiOrPesananBaru = null;
            state.getOrderListBuyerResultMenungguKonfirmasiOrPesananBaru = null;
            state.getOrderListBuyerHasMoreMenungguKonfirmasiOrPesananBaru = false;
            state.getOrderListBuyerMessageMenungguKonfirmasiOrPesananBaru = null;
            state.getOrderListBuyerErrorMenungguKonfirmasiOrPesananBaru = null;
        },

        getOrderListBuyerStartDiprosesOrSiapDikirim: (state) => {
            state.getOrderListBuyerLoadingDiprosesOrSiapDikirim = true;
        },
        getOrderListBuyerFinishDiprosesOrSiapDikirim: (state) => {
            state.getOrderListBuyerLoadingDiprosesOrSiapDikirim = false;
        },
        getOrderListBuyerSuccessDiprosesOrSiapDikirim: (state, action) => {
            const getOrderListBuyerResultDiprosesNew = updateResult(state.getOrderListBuyerResultDiprosesOrSiapDikirim, action.payload?.result);
            state.getOrderListBuyerResultDiprosesOrSiapDikirim = getOrderListBuyerResultDiprosesNew;
            state.getOrderListBuyerHasMoreDiprosesOrSiapDikirim = action.payload?.hasMore;
            state.getOrderListBuyerMessageDiprosesOrSiapDikirim = getOrderListBuyerResultDiprosesNew === null ? 'Belum ada transaksi' : null;
            state.getOrderListBuyerErrorDiprosesOrSiapDikirim = null;
        },
        getOrderListBuyerFailureDiprosesOrSiapDikirim: (state, action) => {
            state.getOrderListBuyerErrorDiprosesOrSiapDikirim = action.payload;
        },
        getOrderListBuyerFailureResetDiprosesOrSiapDikirim: (state) => {
            state.getOrderListBuyerErrorDiprosesOrSiapDikirim = null;
        },
        getOrderListBuyerNextDiprosesOrSiapDikirim: (state) => {
            if (state.getOrderListBuyerResultDiprosesOrSiapDikirim === null) return;
            state.getOrderListBuyerIteratorDiprosesOrSiapDikirim = new Date(state.getOrderListBuyerResultDiprosesOrSiapDikirim?.[state.getOrderListBuyerResultDiprosesOrSiapDikirim.length - 1]?.createdAt).toISOString();
        },
        getOrderListBuyerResetDiprosesOrSiapDikirim: (state) => {
            state.getOrderListBuyerIteratorDiprosesOrSiapDikirim = null;
            state.getOrderListBuyerResultDiprosesOrSiapDikirim = null;
            state.getOrderListBuyerHasMoreDiprosesOrSiapDikirim = false;
            state.getOrderListBuyerMessageDiprosesOrSiapDikirim = null;
            state.getOrderListBuyerErrorDiprosesOrSiapDikirim = null;
        },

        getOrderListBuyerStartDikirimOrDalamPengiriman: (state) => {
            state.getOrderListBuyerLoadingDikirimOrDalamPengiriman = true;
        },
        getOrderListBuyerFinishDikirimOrDalamPengiriman: (state) => {
            state.getOrderListBuyerLoadingDikirimOrDalamPengiriman = false;
        },
        getOrderListBuyerSuccessDikirimOrDalamPengiriman: (state, action) => {
            const getOrderListBuyerResultDikirimNew = updateResult(state.getOrderListBuyerResultDikirimOrDalamPengiriman, action.payload?.result);
            state.getOrderListBuyerResultDikirimOrDalamPengiriman = getOrderListBuyerResultDikirimNew;
            state.getOrderListBuyerHasMoreDikirimOrDalamPengiriman = action.payload?.hasMore;
            state.getOrderListBuyerMessageDikirimOrDalamPengiriman = getOrderListBuyerResultDikirimNew === null ? 'Belum ada transaksi' : null;
            state.getOrderListBuyerErrorDikirimOrDalamPengiriman = null;
        },
        getOrderListBuyerFailureDikirimOrDalamPengiriman: (state, action) => {
            state.getOrderListBuyerErrorDikirimOrDalamPengiriman = action.payload;
        },
        getOrderListBuyerFailureResetDikirimOrDalamPengiriman: (state) => {
            state.getOrderListBuyerErrorDikirimOrDalamPengiriman = null;
        },
        getOrderListBuyerNextDikirimOrDalamPengiriman: (state) => {
            if (state.getOrderListBuyerResultDikirimOrDalamPengiriman === null) return;
            state.getOrderListBuyerIteratorDikirimOrDalamPengiriman = new Date(state.getOrderListBuyerResultDikirimOrDalamPengiriman?.[state.getOrderListBuyerResultDikirimOrDalamPengiriman.length - 1]?.createdAt).toISOString();
        },
        getOrderListBuyerResetDikirimOrDalamPengiriman: (state) => {
            state.getOrderListBuyerIteratorDikirimOrDalamPengiriman = null;
            state.getOrderListBuyerResultDikirimOrDalamPengiriman = null;
            state.getOrderListBuyerHasMoreDikirimOrDalamPengiriman = false;
            state.getOrderListBuyerMessageDikirimOrDalamPengiriman = null;
            state.getOrderListBuyerErrorDikirimOrDalamPengiriman = null;
        },

        getOrderListBuyerStartSampaiTujuan: (state) => {
            state.getOrderListBuyerLoadingSampaiTujuan = true;
        },
        getOrderListBuyerFinishSampaiTujuan: (state) => {
            state.getOrderListBuyerLoadingSampaiTujuan = false;
        },
        getOrderListBuyerSuccessSampaiTujuan: (state, action) => {
            const getOrderListBuyerResultSampaiTujuanNew = updateResult(state.getOrderListBuyerResultSampaiTujuan, action.payload?.result);
            state.getOrderListBuyerResultSampaiTujuan = getOrderListBuyerResultSampaiTujuanNew;
            state.getOrderListBuyerHasMoreSampaiTujuan = action.payload?.hasMore;
            state.getOrderListBuyerMessageSampaiTujuan = getOrderListBuyerResultSampaiTujuanNew === null ? 'Belum ada transaksi' : null;
            state.getOrderListBuyerErrorSampaiTujuan = null;
        },
        getOrderListBuyerFailureSampaiTujuan: (state, action) => {
            state.getOrderListBuyerErrorSampaiTujuan = action.payload;
        },
        getOrderListBuyerFailureResetSampaiTujuan: (state) => {
            state.getOrderListBuyerErrorSampaiTujuan = null;
        },
        getOrderListBuyerNextSampaiTujuan: (state) => {
            if (state.getOrderListBuyerResultSampaiTujuan === null) return;
            state.getOrderListBuyerIteratorSampaiTujuan = new Date(state.getOrderListBuyerResultSampaiTujuan?.[state.getOrderListBuyerResultSampaiTujuan.length - 1]?.createdAt).toISOString();
        },
        getOrderListBuyerResetSampaiTujuan: (state) => {
            state.getOrderListBuyerIteratorSampaiTujuan = null;
            state.getOrderListBuyerResultSampaiTujuan = null;
            state.getOrderListBuyerHasMoreSampaiTujuan = false;
            state.getOrderListBuyerMessageSampaiTujuan = null;
            state.getOrderListBuyerErrorSampaiTujuan = null;
        },

        getOrderListBuyerStartBerhasilOrPesananSelesai: (state) => {
            state.getOrderListBuyerLoadingBerhasilOrPesananSelesai = true;
        },
        getOrderListBuyerFinishBerhasilOrPesananSelesai: (state) => {
            state.getOrderListBuyerLoadingBerhasilOrPesananSelesai = false;
        },
        getOrderListBuyerSuccessBerhasilOrPesananSelesai: (state, action) => {
            const getOrderListBuyerResultBerhasilNew = updateResult(state.getOrderListBuyerResultBerhasilOrPesananSelesai, action.payload?.result);
            state.getOrderListBuyerResultBerhasilOrPesananSelesai = getOrderListBuyerResultBerhasilNew;
            state.getOrderListBuyerHasMoreBerhasilOrPesananSelesai = action.payload?.hasMore;
            state.getOrderListBuyerMessageBerhasilOrPesananSelesai = getOrderListBuyerResultBerhasilNew === null ? 'Belum ada transaksi' : null;
            state.getOrderListBuyerErrorBerhasilOrPesananSelesai = null;
        },
        getOrderListBuyerFailureBerhasilOrPesananSelesai: (state, action) => {
            state.getOrderListBuyerErrorBerhasilOrPesananSelesai = action.payload;
        },
        getOrderListBuyerFailureResetBerhasilOrPesananSelesai: (state) => {
            state.getOrderListBuyerErrorBerhasilOrPesananSelesai = null;
        },
        getOrderListBuyerNextBerhasilOrPesananSelesai: (state) => {
            if (state.getOrderListBuyerResultBerhasilOrPesananSelesai === null) return;
            state.getOrderListBuyerIteratorBerhasilOrPesananSelesai = new Date(state.getOrderListBuyerResultBerhasilOrPesananSelesai?.[state.getOrderListBuyerResultBerhasilOrPesananSelesai.length - 1]?.createdAt).toISOString();
        },
        getOrderListBuyerResetBerhasilOrPesananSelesai: (state) => {
            state.getOrderListBuyerIteratorBerhasilOrPesananSelesai = null;
            state.getOrderListBuyerResultBerhasilOrPesananSelesai = null;
            state.getOrderListBuyerHasMoreBerhasilOrPesananSelesai = false;
            state.getOrderListBuyerMessageBerhasilOrPesananSelesai = null;
            state.getOrderListBuyerErrorBerhasilOrPesananSelesai = null;
        },

        getOrderListBuyerStartTidakBerhasilOrDibatalkanPenjual: (state) => {
            state.getOrderListBuyerLoadingTidakBerhasilOrDibatalkanPenjual = true;
        },
        getOrderListBuyerFinishTidakBerhasilOrDibatalkanPenjual: (state) => {
            state.getOrderListBuyerLoadingTidakBerhasilOrDibatalkanPenjual = false;
        },
        getOrderListBuyerSuccessTidakBerhasilOrDibatalkanPenjual: (state, action) => {
            const getOrderListBuyerResultTidakBerhasilNew = updateResult(state.getOrderListBuyerResultTidakBerhasilOrDibatalkanPenjual, action.payload?.result);
            state.getOrderListBuyerResultTidakBerhasilOrDibatalkanPenjual = getOrderListBuyerResultTidakBerhasilNew;
            state.getOrderListBuyerHasMoreTidakBerhasilOrDibatalkanPenjual = action.payload?.hasMore;
            state.getOrderListBuyerMessageTidakBerhasilOrDibatalkanPenjual = getOrderListBuyerResultTidakBerhasilNew === null ? 'Belum ada transaksi' : null;
            state.getOrderListBuyerErrorTidakBerhasilOrDibatalkanPenjual = null;
        },
        getOrderListBuyerFailureTidakBerhasilOrDibatalkanPenjual: (state, action) => {
            state.getOrderListBuyerErrorTidakBerhasilOrDibatalkanPenjual = action.payload;
        },
        getOrderListBuyerFailureResetTidakBerhasilOrDibatalkanPenjual: (state) => {
            state.getOrderListBuyerErrorTidakBerhasilOrDibatalkanPenjual = null;
        },
        getOrderListBuyerNextTidakBerhasilOrDibatalkanPenjual: (state) => {
            if (state.getOrderListBuyerResultTidakBerhasilOrDibatalkanPenjual === null) return;
            state.getOrderListBuyerIteratorTidakBerhasilOrDibatalkanPenjual = new Date(state.getOrderListBuyerResultTidakBerhasilOrDibatalkanPenjual?.[state.getOrderListBuyerResultTidakBerhasilOrDibatalkanPenjual.length - 1]?.createdAt).toISOString();
        },
        getOrderListBuyerResetTidakBerhasilOrDibatalkanPenjual: (state) => {
            state.getOrderListBuyerIteratorTidakBerhasilOrDibatalkanPenjual = null;
            state.getOrderListBuyerResultTidakBerhasilOrDibatalkanPenjual = null;
            state.getOrderListBuyerHasMoreTidakBerhasilOrDibatalkanPenjual = false;
            state.getOrderListBuyerMessageTidakBerhasilOrDibatalkanPenjual = null;
            state.getOrderListBuyerErrorTidakBerhasilOrDibatalkanPenjual = null;
        },

        getOrderListSellerStartSemua: (state) => {
            state.getOrderListSellerLoadingSemua = true;
        },
        getOrderListSellerFinishSemua: (state) => {
            state.getOrderListSellerLoadingSemua = false;
        },
        getOrderListSellerSuccessSemua: (state, action) => {
            const getOrderListSellerResultSemuaNew = updateResult(state.getOrderListSellerResultSemua, action.payload?.result);
            state.getOrderListSellerResultSemua = getOrderListSellerResultSemuaNew;
            state.getOrderListSellerHasMoreSemua = action.payload?.hasMore;
            state.getOrderListSellerMessageSemua = getOrderListSellerResultSemuaNew === null ? 'Belum ada transaksi' : null;
            state.getOrderListSellerErrorSemua = null;
        },
        getOrderListSellerFailureSemua: (state, action) => {
            state.getOrderListSellerErrorSemua = action.payload;
        },
        getOrderListSellerFailureResetSemua: (state) => {
            state.getOrderListSellerErrorSemua = null;
        },
        getOrderListSellerNextSemua: (state) => {
            if (state.getOrderListSellerResultSemua === null) return;
            state.getOrderListSellerIteratorSemua = new Date(state.getOrderListSellerResultSemua?.[state.getOrderListSellerResultSemua.length - 1]?.createdAt).toISOString();
        },
        getOrderListSellerResetSemua: (state) => {
            state.getOrderListSellerIteratorSemua = null;
            state.getOrderListSellerResultSemua = null;
            state.getOrderListSellerHasMoreSemua = false;
            state.getOrderListSellerMessageSemua = null;
            state.getOrderListSellerErrorSemua = null;
        },

        getOrderListSellerStartMenungguKonfirmasiOrPesananBaru: (state) => {
            state.getOrderListSellerLoadingMenungguKonfirmasiOrPesananBaru = true;
        },
        getOrderListSellerFinishMenungguKonfirmasiOrPesananBaru: (state) => {
            state.getOrderListSellerLoadingMenungguKonfirmasiOrPesananBaru = false;
        },
        getOrderListSellerSuccessMenungguKonfirmasiOrPesananBaru: (state, action) => {
            const getOrderListSellerResultMenungguKonfirmasiNew = updateResult(state.getOrderListSellerResultMenungguKonfirmasiOrPesananBaru, action.payload?.result);
            state.getOrderListSellerResultMenungguKonfirmasiOrPesananBaru = getOrderListSellerResultMenungguKonfirmasiNew;
            state.getOrderListSellerHasMoreMenungguKonfirmasiOrPesananBaru = action.payload?.hasMore;
            state.getOrderListSellerMessageMenungguKonfirmasiOrPesananBaru = getOrderListSellerResultMenungguKonfirmasiNew === null ? 'Belum ada transaksi' : null;
            state.getOrderListSellerErrorMenungguKonfirmasiOrPesananBaru = null;
        },
        getOrderListSellerFailureMenungguKonfirmasiOrPesananBaru: (state, action) => {
            state.getOrderListSellerErrorMenungguKonfirmasiOrPesananBaru = action.payload;
        },
        getOrderListSellerFailureResetMenungguKonfirmasiOrPesananBaru: (state) => {
            state.getOrderListSellerErrorMenungguKonfirmasiOrPesananBaru = null;
        },
        getOrderListSellerNextMenungguKonfirmasiOrPesananBaru: (state) => {
            if (state.getOrderListSellerResultMenungguKonfirmasiOrPesananBaru === null) return;
            state.getOrderListSellerIteratorMenungguKonfirmasiOrPesananBaru = new Date(state.getOrderListSellerResultMenungguKonfirmasiOrPesananBaru?.[state.getOrderListSellerResultMenungguKonfirmasiOrPesananBaru.length - 1]?.createdAt).toISOString();
        },
        getOrderListSellerResetMenungguKonfirmasiOrPesananBaru: (state) => {
            state.getOrderListSellerIteratorMenungguKonfirmasiOrPesananBaru = null;
            state.getOrderListSellerResultMenungguKonfirmasiOrPesananBaru = null;
            state.getOrderListSellerHasMoreMenungguKonfirmasiOrPesananBaru = false;
            state.getOrderListSellerMessageMenungguKonfirmasiOrPesananBaru = null;
            state.getOrderListSellerErrorMenungguKonfirmasiOrPesananBaru = null;
        },

        getOrderListSellerStartDiprosesOrSiapDikirim: (state) => {
            state.getOrderListSellerLoadingDiprosesOrSiapDikirim = true;
        },
        getOrderListSellerFinishDiprosesOrSiapDikirim: (state) => {
            state.getOrderListSellerLoadingDiprosesOrSiapDikirim = false;
        },
        getOrderListSellerSuccessDiprosesOrSiapDikirim: (state, action) => {
            const getOrderListSellerResultDiprosesNew = updateResult(state.getOrderListSellerResultDiprosesOrSiapDikirim, action.payload?.result);
            state.getOrderListSellerResultDiprosesOrSiapDikirim = getOrderListSellerResultDiprosesNew;
            state.getOrderListSellerHasMoreDiprosesOrSiapDikirim = action.payload?.hasMore;
            state.getOrderListSellerMessageDiprosesOrSiapDikirim = getOrderListSellerResultDiprosesNew === null ? 'Belum ada transaksi' : null;
            state.getOrderListSellerErrorDiprosesOrSiapDikirim = null;
        },
        getOrderListSellerFailureDiprosesOrSiapDikirim: (state, action) => {
            state.getOrderListSellerErrorDiprosesOrSiapDikirim = action.payload;
        },
        getOrderListSellerFailureResetDiprosesOrSiapDikirim: (state) => {
            state.getOrderListSellerErrorDiprosesOrSiapDikirim = null;
        },
        getOrderListSellerNextDiprosesOrSiapDikirim: (state) => {
            if (state.getOrderListSellerResultDiprosesOrSiapDikirim === null) return;
            state.getOrderListSellerIteratorDiprosesOrSiapDikirim = new Date(state.getOrderListSellerResultDiprosesOrSiapDikirim?.[state.getOrderListSellerResultDiprosesOrSiapDikirim.length - 1]?.createdAt).toISOString();
        },
        getOrderListSellerResetDiprosesOrSiapDikirim: (state) => {
            state.getOrderListSellerIteratorDiprosesOrSiapDikirim = null;
            state.getOrderListSellerResultDiprosesOrSiapDikirim = null;
            state.getOrderListSellerHasMoreDiprosesOrSiapDikirim = false;
            state.getOrderListSellerMessageDiprosesOrSiapDikirim = null;
            state.getOrderListSellerErrorDiprosesOrSiapDikirim = null;
        },

        getOrderListSellerStartDikirimOrDalamPengiriman: (state) => {
            state.getOrderListSellerLoadingDikirimOrDalamPengiriman = true;
        },
        getOrderListSellerFinishDikirimOrDalamPengiriman: (state) => {
            state.getOrderListSellerLoadingDikirimOrDalamPengiriman = false;
        },
        getOrderListSellerSuccessDikirimOrDalamPengiriman: (state, action) => {
            const getOrderListSellerResultDikirimNew = updateResult(state.getOrderListSellerResultDikirimOrDalamPengiriman, action.payload?.result);
            state.getOrderListSellerResultDikirimOrDalamPengiriman = getOrderListSellerResultDikirimNew;
            state.getOrderListSellerHasMoreDikirimOrDalamPengiriman = action.payload?.hasMore;
            state.getOrderListSellerMessageDikirimOrDalamPengiriman = getOrderListSellerResultDikirimNew === null ? 'Belum ada transaksi' : null;
            state.getOrderListSellerErrorDikirimOrDalamPengiriman = null;
        },
        getOrderListSellerFailureDikirimOrDalamPengiriman: (state, action) => {
            state.getOrderListSellerErrorDikirimOrDalamPengiriman = action.payload;
        },
        getOrderListSellerFailureResetDikirimOrDalamPengiriman: (state) => {
            state.getOrderListSellerErrorDikirimOrDalamPengiriman = null;
        },
        getOrderListSellerNextDikirimOrDalamPengiriman: (state) => {
            if (state.getOrderListSellerResultDikirimOrDalamPengiriman === null) return;
            state.getOrderListSellerIteratorDikirimOrDalamPengiriman = new Date(state.getOrderListSellerResultDikirimOrDalamPengiriman?.[state.getOrderListSellerResultDikirimOrDalamPengiriman.length - 1]?.createdAt).toISOString();
        },
        getOrderListSellerResetDikirimOrDalamPengiriman: (state) => {
            state.getOrderListSellerIteratorDikirimOrDalamPengiriman = null;
            state.getOrderListSellerResultDikirimOrDalamPengiriman = null;
            state.getOrderListSellerHasMoreDikirimOrDalamPengiriman = false;
            state.getOrderListSellerMessageDikirimOrDalamPengiriman = null;
            state.getOrderListSellerErrorDikirimOrDalamPengiriman = null;
        },

        getOrderListSellerStartBerhasilOrPesananSelesai: (state) => {
            state.getOrderListSellerLoadingBerhasilOrPesananSelesai = true;
        },
        getOrderListSellerFinishBerhasilOrPesananSelesai: (state) => {
            state.getOrderListSellerLoadingBerhasilOrPesananSelesai = false;
        },
        getOrderListSellerSuccessBerhasilOrPesananSelesai: (state, action) => {
            const getOrderListSellerResultBerhasilNew = updateResult(state.getOrderListSellerResultBerhasilOrPesananSelesai, action.payload?.result);
            state.getOrderListSellerResultBerhasilOrPesananSelesai = getOrderListSellerResultBerhasilNew;
            state.getOrderListSellerHasMoreBerhasilOrPesananSelesai = action.payload?.hasMore;
            state.getOrderListSellerMessageBerhasilOrPesananSelesai = getOrderListSellerResultBerhasilNew === null ? 'Belum ada transaksi' : null;
            state.getOrderListSellerErrorBerhasilOrPesananSelesai = null;
        },
        getOrderListSellerFailureBerhasilOrPesananSelesai: (state, action) => {
            state.getOrderListSellerErrorBerhasilOrPesananSelesai = action.payload;
        },
        getOrderListSellerFailureResetBerhasilOrPesananSelesai: (state) => {
            state.getOrderListSellerErrorBerhasilOrPesananSelesai = null;
        },
        getOrderListSellerNextBerhasilOrPesananSelesai: (state) => {
            if (state.getOrderListSellerResultBerhasilOrPesananSelesai === null) return;
            state.getOrderListSellerIteratorBerhasilOrPesananSelesai = new Date(state.getOrderListSellerResultBerhasilOrPesananSelesai?.[state.getOrderListSellerResultBerhasilOrPesananSelesai.length - 1]?.createdAt).toISOString();
        },
        getOrderListSellerResetBerhasilOrPesananSelesai: (state) => {
            state.getOrderListSellerIteratorBerhasilOrPesananSelesai = null;
            state.getOrderListSellerResultBerhasilOrPesananSelesai = null;
            state.getOrderListSellerHasMoreBerhasilOrPesananSelesai = false;
            state.getOrderListSellerMessageBerhasilOrPesananSelesai = null;
            state.getOrderListSellerErrorBerhasilOrPesananSelesai = null;
        },

        getOrderListSellerStartTidakBerhasilOrDibatalkanPenjual: (state) => {
            state.getOrderListSellerLoadingTidakBerhasilOrDibatalkanPenjual = true;
        },
        getOrderListSellerFinishTidakBerhasilOrDibatalkanPenjual: (state) => {
            state.getOrderListSellerLoadingTidakBerhasilOrDibatalkanPenjual = false;
        },
        getOrderListSellerSuccessTidakBerhasilOrDibatalkanPenjual: (state, action) => {
            const getOrderListSellerResultTidakBerhasilNew = updateResult(state.getOrderListSellerResultTidakBerhasilOrDibatalkanPenjual, action.payload?.result);
            state.getOrderListSellerResultTidakBerhasilOrDibatalkanPenjual = getOrderListSellerResultTidakBerhasilNew;
            state.getOrderListSellerHasMoreTidakBerhasilOrDibatalkanPenjual = action.payload?.hasMore;
            state.getOrderListSellerMessageTidakBerhasilOrDibatalkanPenjual = getOrderListSellerResultTidakBerhasilNew === null ? 'Belum ada transaksi' : null;
            state.getOrderListSellerErrorTidakBerhasilOrDibatalkanPenjual = null;
        },
        getOrderListSellerFailureTidakBerhasilOrDibatalkanPenjual: (state, action) => {
            state.getOrderListSellerErrorTidakBerhasilOrDibatalkanPenjual = action.payload;
        },
        getOrderListSellerFailureResetTidakBerhasilOrDibatalkanPenjual: (state) => {
            state.getOrderListSellerErrorTidakBerhasilOrDibatalkanPenjual = null;
        },
        getOrderListSellerNextTidakBerhasilOrDibatalkanPenjual: (state) => {
            if (state.getOrderListSellerResultTidakBerhasilOrDibatalkanPenjual === null) return;
            state.getOrderListSellerIteratorTidakBerhasilOrDibatalkanPenjual = new Date(state.getOrderListSellerResultTidakBerhasilOrDibatalkanPenjual?.[state.getOrderListSellerResultTidakBerhasilOrDibatalkanPenjual.length - 1]?.createdAt).toISOString();
        },
        getOrderListSellerResetTidakBerhasilOrDibatalkanPenjual: (state) => {
            state.getOrderListSellerIteratorTidakBerhasilOrDibatalkanPenjual = null;
            state.getOrderListSellerResultTidakBerhasilOrDibatalkanPenjual = null;
            state.getOrderListSellerHasMoreTidakBerhasilOrDibatalkanPenjual = false;
            state.getOrderListSellerMessageTidakBerhasilOrDibatalkanPenjual = null;
            state.getOrderListSellerErrorTidakBerhasilOrDibatalkanPenjual = null;
        },

        getOrderByInvoiceCodeStart: (state) => {
            state.getOrderByInvoiceCodeLoading = true;
        },
        getOrderByInvoiceCodeEnd: (state) => {
            state.getOrderByInvoiceCodeLoading = false;
        },
        getOrderByInvoiceCodeSuccess: (state, action) => {
            state.getOrderByInvoiceCodeResult = action.payload;
        },
        getOrderByInvoiceCodeFailure: (state, action) => {
            state.getOrderByInvoiceCodeError = action.payload;
        },
    }
});

export const orderAction = orderSlice.actions;
export const orderSelector = (state) => state.order;
export default orderSlice.reducer;
