import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    getOneLoading: true,
    getOneResult: null,
    getOneError: null,
    updateSuccess: null,
    updateError: null
};
export const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {
        resetState: () => initialState,
        getOneStart: (state) => {
            state.getOneLoading = true;
        },
        getOneEnd: (state) => {
            state.getOneLoading = false;
        },
        getOneSuccess: (state, action) => {
            state.getOneResult = action.payload;
            state.getOneError = null;
        },
        getOneFailure: (state, action) => {
            state.getOneError = action.payload;
        },
        updateProfilePhoneVerified: (state, action) => {
            if (state.getOneResult === null) return;
            state.getOneResult = { ...state.getOneResult, phoneVerified: action.payload?.phoneVerified };
            state.updateError = null;
        },
        updateProfileChatSetting: (state, action) => {
            if (state.getOneResult === null) return;
            state.getOneResult = { ...state.getOneResult, chatSettings: action.payload };
            state.updateSuccess = 'Chat settings berhasil diupdate.';
            state.updateError = null;
        },
        updateProfilePhone: (state, action) => {
            if (state.getOneResult === null) return;
            state.getOneResult = { ...state.getOneResult, phone: action.payload?.phone, phoneVerified: false };
            state.updateSuccess = 'Nomor handphone berhasil diupdate.';
            state.updateError = null;
        },
        updateProfileName: (state, action) => {
            if (state.getOneResult === null) return;
            state.getOneResult = { ...state.getOneResult, name: action.payload?.name };
            state.updateSuccess = 'Nama berhasil diupdate.';
            state.updateError = null;
        },
        updateProfileGender: (state, action) => {
            if (state.getOneResult === null) return;
            state.getOneResult = { ...state.getOneResult, gender: action.payload?.gender };
            state.updateSuccess = 'Jenis Kelamin berhasil diupdate.';
            state.updateError = null;
        },
        updateDocument: (state, action) => {
            if (state.getOneResult === null) return;
            state.getOneResult = { ...state.getOneResult, profilePicture: action.payload?.profilePicture };
            state.updateSuccess = 'Profile Picture berhasil diupdate.';
            state.updateError = null;
        },
        updateRole: (state, action) => {
            if (state.getOneResult === null) return;
            if (action.payload?.dissaproved) {
                state.getOneResult = { ...state.getOneResult, role: action.payload?.role, dissaproved: action.payload?.dissaproved, dissaprovedAt: action.payload?.dissaprovedAt };
                return;
            }
            state.getOneResult = { ...state.getOneResult, role: action.payload?.role };
        },
        updateSuccess: (state, action) => {
            state.updateSuccess = action.payload;
        },
        updateSuccessReset: (state) => {
            state.updateSuccess = null;
        },
        updateFailure: (state, action) => {
            state.updateError = action.payload;
        },
        updateFailureReset: (state) => {
            state.updateError = null;
        }
    }
});

export const userProfileAction = userProfileSlice.actions;
export const userProfileSelector = (state) => state.userProfile;
export default userProfileSlice.reducer;
