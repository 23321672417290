import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LOCALSTORAGE } from '../configs/constants';
import useSocketContext from '../hooks/useSocketContext';
import { userAuthAction, userAuthSelector } from '../redux/slices/userAuthSlice';
import { userReauth } from '../redux/actions/userAuthAsyncActionList';
import LoadingSpinner from './LoadingSpinner';
import SuperAdminPanel from './SuperAdminPanel';

const PersistLogin = () => {
    const { socket } = useSocketContext();
    const dispatch = useDispatch();
    const { authResult, authLoading } = useSelector(userAuthSelector);
    const persist = localStorage.getItem(LOCALSTORAGE.rememberMe.key) === "true";

    useEffect(() => {
        if (persist === false) dispatch(userAuthAction.authLoadingEnd());
        if (persist === true) dispatch(userReauth(socket));
    }, [persist, authResult?.accessToken]);

    return persist === false
        ? (
            <>
                {['localdev', 'clouddev'].includes(process.env.REACT_APP_NODE_ENV) && <SuperAdminPanel />}
                <Outlet />
            </>
        ) : authLoading
            ? <LoadingSpinner />
            : (
                <>
                    {['localdev', 'clouddev'].includes(process.env.REACT_APP_NODE_ENV) && <SuperAdminPanel />}
                    <Outlet />
                </>
            );
};

export default PersistLogin;
