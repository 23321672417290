import React, { lazy, Suspense } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';

const SellerProfile = lazy(() => import('../components/SellerProfile'));

const LazySellerProfile = () => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <SellerProfile />
        </Suspense>
    );
};

export default LazySellerProfile;
